/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge, Image, Collapse, Modal, FormControl, Button } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import { Link } from "react-router-dom";
import { TimeSense } from "../../Common/TimeSense";
import MoreIcon from '../../../assets/img/more_options.png';
import { getTableSorting } from "../../../redux/actions/adminActions";
import OrderStatusModel from "../../Common/OrderStatusModel";
import CommonExport from "../../Common/CommonExport";
import Select from 'react-select';

const SOCancelled = (props) => {
    const { RouteName } = props;
    const Dispatch = useDispatch();
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("all");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [activeTableDetails, setActiveTableDetails] = useState("");
    const [orderSource, setOrderSource] = useState("");
    const [ selectRegion, setSelectRegion ] = useState("");
    const [ selectWarehouse, setSelectWarehouse ] = useState("");
    const today = new Date();
    const { device_id, CancelledOrderList, Regionlistall, sortingfor, ClearFormSet, wareHouselistall } = useSelector((state) => state.adminReducers);
    const optionsWarehouse = wareHouselistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: `${item?.warehouse_name} - (${item?.warehouse_code})- (${item?.display_name})` }));
    const [OrderViewerModal, setOrderViewerModal] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    const [showModal, setShowModal] = useState({
        open: false,
        title: "",
        modalType: "",
        Reason: "",
        Data: {}
    });
    const [ errorReason, setErrorReason ] = useState("");

    //order model
    const [OrderNewModel, setOrderNewModel] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // close modal
    const onClose = () => {
        setShowModal({
            ...showModal,
            open: false,
        })
        setErrorReason("");
    };

    // accept modal open
    const ModalAcceptOpen = (data , url) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: `Order ${url}`,
            Data: data
        })
    };

    // order success reject flow
    const OrderSuccesReject = (url) => {
        if(showModal?.Reason?.trim() !== "") {
            setErrorReason("");
            const param = {
                "transmit": "broadcast",
                "url": "order_cancelled",
                "request" :{
                    "order_id" : showModal?.Data?.id,
                    "cancel_reason" : showModal?.Reason?.trim(),
                    "order_status" : url === "Order Accept" ? "CANCELLED" : showModal?.Data?.cancel_stage,
                },
                'DeviceId':device_id,
            }
            wsSend_request(websocket, param);
        } else {
            setErrorReason("Please enter reason")
        };
    };

    //order status
    const showOrder = (value) => {
        setOrderNewModel({
            ...OrderNewModel,
            open: !OrderNewModel?.open,
            title: "Order Log",
            subtitle: "",
            Data: value
        });
    };

    useEffect(() => {
        Dispatch(getTableSorting(""))
    }, [])

    // get all warehouse list
    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "warehouse_list",
            "request": {
                "status": "",
                "limit": 5000,
                "page_no": 1,
                "search": ""
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get all region list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);


    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    const selectRegionFunction = (e) => {
        setSelectRegion(e.target.value);
        setCurrentPage(1);
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            // setEndDate(date)
            setToDate(date);
        }
    };

    // wareehouse select
    const WareHouseSelect = (value) => {
        if(value !== null) {
            setSelectWarehouse(value);
        } else {
            setSelectWarehouse("");
        };
    };

    // get order_list all
    useEffect(() => {
        if (websocket) {
            SOCancelled();
        }
    }, [websocket, userSearch, orderSource, selectWarehouse, selectRegion, userType, currentPage, userLimit, FromDate, ToDate, sortingfor]);

    const SOCancelled = () => {
        let param = {
            "transmit": "broadcast",
            "url": "order_list",
            "request": {
                "status": "CANCELLED,CANCELLED_REQUEST",
                "limit": userLimit,
                "page_no": currentPage,
                "search": userSearch.trim(),
                "from_date": null,
                "to_date": null,
                "order_type": orderSource,
                "region_id": selectRegion,
                "warehouse_id": selectWarehouse?.value,
                "order_by": sortingfor,
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    };

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    // get franchise name
    const GetFranchiseName = (billing_id) => {
        const current = CancelledOrderList?.franchise?.filter((elm) => elm?.id === billing_id)[0]
        return {
            name: current?.customer_name
        };
    };

    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    const CommonSortingFct = (url) => {
        if (sortingfor === url) {
            Dispatch(getTableSorting("-" + url))
        } else {
            Dispatch(getTableSorting(url))
        };
    };

    const GetRegionName = (id) => {
        if(id === null) {
            return "-";
        } else {
            const currentRegionData = CancelledOrderList?.region_list?.filter((elm) => elm?.id === id)[0];
            return currentRegionData?.region_name
        }
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url == "order_cancelled") {
            setShowModal({
                ...showModal,
                open: false,
                title: "",
                modalType: "",
                Reason: "",
                Data: {}
            });
            SOCancelled();
        };
    }, [ ClearFormSet ]);

    return (<section className="Main_section">
        <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3" style={{ width: "23%" }}>
                        <Form.Group>
                            <div className="datepickField min">
                                <DatePicker
                                    selected={FromDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    onChange={(date) => DateStartGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={FromDate !== "" ? true : false}
                                    name="statDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "23%" }}>
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker
                                    selected={ToDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    minDate={startDate}
                                    maxDate={endDate}
                                    onChange={(date) => DateEndGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={ToDate !== "" ? true : false}
                                    name="ToDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "23%" }}>
                        <Form.Group>
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    value={selectWarehouse}
                                    onChange={(e) => WareHouseSelect(e)}
                                    isClearable
                                    options={optionsWarehouse}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Warehouse"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "15%" }}>
                        <select className="form-control activestatusselet pe-4" id="columnfullwith" value={selectRegion} onChange={(e) => selectRegionFunction(e)}>
                            <option value="">Select Region</option>
                            {Regionlistall?.map((elm, index) => {
                                return(<option value={elm?.id} key={index}>{elm?.region_name}</option>)
                            })}
                        </select>
                    </div>
                    <div className="col-md-3" style={{ width: "auto" }}>
                        <button className="btn exportbtn" disabled={ToDate === "" || FromDate === ""} style={{ width: "auto" }} onClick={() => CommonExport("order_list", { "FromDate": FromDate, "ToDate": ToDate, "status": "CANCELLED", "region_id": selectRegion, "search": userSearch.trim(), "order_type": orderSource })}>
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <DataTableFilter
            filterType={""}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            callSelectSource={setOrderSource}
            orderSource={orderSource}
            searchType={"Order Id, Name, Customer Code"}
        />
        <div className="tableView orderlistTable gstreporttableadded">
            <Scrollbars
                style={{ height: CancelledOrderList?.pagination?.total_records > 10 ? "calc(100vh - 245px)" : "calc(100vh - 215px)" }}
                renderView={props => <div {...props} className="view" />}
                renderTrackHorizontal={renderTrack}
                className="ScrollbarsSidebar "
            >
                <Table responsive bordered hover >
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNo</th>
                            <th className={sortingfor === "order_code" ? "active" : sortingfor === "-order_code" ? "inactive" : ""} onClick={() => CommonSortingFct("order_code")}>Order Id</th>
                            <th className={sortingfor === "customer_identity" ? "active" : sortingfor === "-customer_identity" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_identity")}>Customer Code</th>
                            <th className={sortingfor === "created_at" ? "active" : sortingfor === "-created_at" ? "inactive" : ""} onClick={() => CommonSortingFct("created_at")}>Order Date</th>
                            <th className={sortingfor === "order_source" ? "active" : sortingfor === "-order_source" ? "inactive" : ""} onClick={() => CommonSortingFct("order_source")}>Order Source</th>
                            <th className={sortingfor === "customer_name" ? "active" : sortingfor === "-customer_name" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_name")}>SHIP To Name</th>
                            <th className={sortingfor === "billing_id" ? "active" : sortingfor === "-billing_id" ? "inactive" : ""} onClick={() => CommonSortingFct("billing_id")}>BILL To Name</th>
                            <th className={sortingfor === "mobile" ? "active" : sortingfor === "-mobile" ? "inactive" : ""} onClick={() => CommonSortingFct("mobile")}>Contact</th>
                            <th className={sortingfor === "qty" ? "active" : sortingfor === "-qty" ? "inactive" : ""} onClick={() => CommonSortingFct("qty")}>Quantity</th>
                            <th className={sortingfor === "total" ? "active" : sortingfor === "-total" ? "inactive" : ""} onClick={() => CommonSortingFct("total")}>Order Value</th>
                            <th className={sortingfor === "cancel_stage" ? "active" : sortingfor === "-cancel_stage" ? "inactive" : ""} onClick={() => CommonSortingFct("cancel_stage")}>Rejection Stage</th>
                            <th className={sortingfor === "updated_at" ? "active" : sortingfor === "-updated_at" ? "inactive" : ""} onClick={() => CommonSortingFct("updated_at")}>Pending Since </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {CancelledOrderList?.data?.map((elm, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <tr >
                                        <td style={{ width: '10px' }}>
                                            <button
                                                className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                                onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={activeTableDetails === elm?.id ? true : false}
                                            >
                                                <Image src={MoreIcon} alt="more icon" />
                                            </button>
                                        </td>
                                        <td>
                                            <Link className="btn-link btn-link-color text-decoration-none" onClick={() => showOrder(elm?.id)}>
                                                {GetCurrentTableSRNo(index, CancelledOrderList?.pagination?.current_page, CancelledOrderList?.pagination?.record_limit)}
                                            </Link>
                                        </td>
                                        <td>
                                            <b className="tableBolanchor">{elm?.order_code}</b>
                                        </td>
                                        <td>
                                            {elm?.customer_identity === null ? "-" : elm?.customer_identity}
                                        </td>
                                        <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                        <td>{elm?.order_source === null ? "-" : elm?.order_source}</td>
                                        <td>{elm?.customer_name !== null ? elm?.customer_name : "-"}</td>
                                        <td>{GetFranchiseName(elm?.billing_id)?.name === undefined ? "-" : GetFranchiseName(elm?.billing_id)?.name}</td>
                                        <td>{elm?.mobile}</td>
                                        <td>{elm?.qty}</td>
                                        <td>{AmountNumberFormat(elm?.total)}</td>
                                        <td>
                                            {elm?.cancel_stage === "SO_APPROVED" && "Warehouse Approval"}
                                            {elm?.cancel_stage === "OPEN" && "SO Approvals"}
                                            {elm?.cancel_stage === "WH_APPROVED" && "Pending For Accounts"}
                                            {elm?.cancel_stage === "AC_APPROVED" && "Pending For Stores"}
                                            {elm?.cancel_stage === "DISPATCHED" && "Pending For Delivery"}
                                            {(elm?.cancel_stage === "ST_APPROVED" || elm?.cancel_stage === "HALF_DISPATCH") && "Pending For Dispatch"}
                                        </td>
                                        <td>{TimeSense(elm?.updated_at)}</td>
                                        <td>
                                            {elm?.order_type === "AO" && (elm?.order_status === "CANCELLED_REQUEST" || elm?.order_status === "RETURN_REQUEST" || elm?.order_status === "REPLACE_REQUEST") ? (<div style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                                                <Button variant="primary" size="sm" onClick={() => ModalAcceptOpen(elm, "Accept")}>Accept</Button>
                                                <Button variant="danger" size="sm"  className="btn" onClick={() => ModalAcceptOpen(elm, "Reject")}>Reject</Button>
                                            </div>) : ("-")}
                                        </td>
                                    </tr>
                                    <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                        <tr className="tablecollapsewraps" id={"tablecollpase" + elm?.id}>
                                            <td colSpan="13" >
                                                <Table bordered hover className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Status</th>
                                                            <td>
                                                                {elm?.order_status === "CANCELLED" && (<Badge bg="danger">Cancelled</Badge>)}
                                                                {elm?.order_status === "REPLACE" && (<Badge bg="danger">Replace</Badge>)}
                                                                {elm?.order_status === "RETURNED" && (<Badge bg="danger">Returned</Badge>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Attachments</th>
                                                            <td>
                                                                {elm?.attachments === null ? "-" : (<ul className="fileuploadListytable">
                                                                    {elm?.attachments?.map((elm, index) => {
                                                                        return (<li key={index}>
                                                                            <a href={process.env.REACT_APP_BASE_URL + "file/" + elm?.view_file_url} download={elm?.attachment_name} target="__blank"><i className="bi bi-file-earmark-text"></i> {elm?.attachment_name}  <i className="bi bi-download downloadIcomn"></i></a>
                                                                        </li>)
                                                                    })}
                                                                </ul>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Region</th>
                                                            <td>{GetRegionName(elm?.region_id)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Cancel Reason</th>
                                                            <td>{elm?.cancel_reason}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </Collapse>
                                </React.Fragment>
                            )
                        })}
                        {CancelledOrderList?.data?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="12">Order cancelled list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {CancelledOrderList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={currentPage}
                paginationLength={CancelledOrderList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        <OrderDetailsViewer
            OrderViewerModal={OrderViewerModal}
            setOrderViewerModal={setOrderViewerModal}
        />
        <OrderStatusModel setOrderNewModel={setOrderNewModel} OrderNewModel={OrderNewModel} />

        {showModal?.open && (<Modal show={showModal?.open} onHide={onClose} className='commonModal' centered>
            <Modal.Header className='border-bottom-0 bb-0 pb-0' closeButton>
                <Modal.Title className='h5'>{showModal?.title} <span className='text-danger' >*</span></Modal.Title>
            </Modal.Header>
            <div className='p-3'>
                <FormControl 
                    value={showModal?.Reason} 
                    onChange={(e) => {
                        setShowModal({
                            ...showModal,
                            Reason: e?.target?.value,
                        });
                    }}
                    placeholder='Enter a reason' as='textarea' 
                />
                {errorReason !== "" &&(<small className='text-danger' >{errorReason}</small>)}
            </div>
           <div className='d-flex  px-3 pb-3'>
                <button className='btn button-1 w-auto ms-auto' onClick={() => OrderSuccesReject(showModal?.title)}>Submit</button>
           </div>
        </Modal>)}
    </section>)
}

export default SOCancelled;