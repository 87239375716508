/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Button , Collapse, Image, Modal, FormControl } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import { FRANCHISE_DISPATCH_PENDING_URL, RETURN_AND_REPLACE_REPORT } from "../../Shared/constant";
import { Link, useNavigate } from "react-router-dom";
import MoreIcon from '../../../assets/img/more_options.png';
import { TimeSense, getColor } from "../../Common/TimeSense";
import { ClearFormSetFutios, getTableSorting } from "../../../redux/actions/adminActions";
import CommonStatus from "../../Common/CommonStatus";
import OrderStatusModel from "../../Common/OrderStatusModel";
import CommonExport from "../../Common/CommonExport";
import BrowserUniqueTabID from "../../Common/BrowserUniqueTabID";

const ReturnAndReplace = (props) => {
    const navigate = useNavigate();
    const { RouteName } = props;
    const [orderSource, setOrderSource] = useState("")
    const Dispatch = useDispatch();
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("all");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [activeTableDetails, setActiveTableDetails] = useState("");
    const today = new Date();
    const [ selectRegion, setSelectRegion ] = useState("");
    const { device_id, ReturnReplaceOrderList, AllDealerFranchiseTypeListAll, Regionlistall, ClearFormSet, sortingfor, selfInfo } = useSelector((state) => state.adminReducers);
    const [OrderViewerModal, setOrderViewerModal] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    // var tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
    var tabID = BrowserUniqueTabID();

    //order model
    const [OrderNewModel, setOrderNewModel] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [showModal, setShowModal] = useState({
        open: false,
        title: "",
        modalType: "",
        Reason: "",
        Data: {}
    });
    const [ errorReason, setErrorReason ] = useState("");

    //order status
    const showOrder = (value) => {
        setOrderNewModel({
            ...OrderNewModel,
            open: !OrderNewModel?.open,
            title: "Order Log",
            subtitle: "",
            // description: `Are you sure! You want to confirm this transaction?</h5>`,
            // modalType: "form",
            // button: "Yes",
            Data: value
        });
    };

    // close modal
    const onClose = () => {
        setShowModal({
            ...showModal,
            open: false,
        })
        setErrorReason("");
    };

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        Dispatch(getTableSorting(""))
    }, [])

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    const selectRegionFunction = (e) => {
        setSelectRegion(e.target.value);
        setCurrentPage(1);
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            // setEndDate(date)
            setToDate(date);
        }
    };


    // get order_list all
    useEffect(() => {
        if (websocket) {
            ReturnReplaceOrder();
        }
    }, [websocket, userSearch, userType, selectRegion, currentPage, userLimit, FromDate, ToDate, orderSource, sortingfor]);

    const ReturnReplaceOrder = () => {
        let param = {
            "transmit": "broadcast",
            "url": "order_list",
            "request": {
                "status": "RETURN,REPLACE,RETURN_REQUEST",
                "limit": userLimit,
                "page_no": currentPage,
                "search": userSearch.trim(),
                "from_date": null,
                "to_date": null,
                "from_inv": null,
                "to_inv": null,
                "order_type": orderSource,
                "region_id": selectRegion,
                "order_by": sortingfor,
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_inv = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_inv = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    };

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    const callInvoiceModal = (e, code) => {
        let param = {
            "transmit": "broadcast",
            "url": "order_detail",
            "request": {
                "order_id": code,
            },
            "DeviceId": device_id,
            "TabId": tabID,
            modal: true
        };
        wsSend_request(websocket, param);
    }
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    const CommonSortingFct = (url) => {
        if (sortingfor === url) {
            Dispatch(getTableSorting("-" + url))
        } else {
            Dispatch(getTableSorting(url))
        };
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200) {
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        };
    }, [ClearFormSet]);

    const cmnFnct = () => {
        navigate(RETURN_AND_REPLACE_REPORT);
    };

    const GetRegionName = (id) => {
        if(id === null) {
            return "-";
        } else {
            const currentRegionData = ReturnReplaceOrderList?.regionlist?.filter((elm) => elm?.id === id)[0];
            return currentRegionData?.region_name
        }
    };

    // accept modal open
    const ModalAcceptOpen = (data , url) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: `Order ${url}`,
            Data: data
        })
    };

    // order success reject flow
    const OrderSuccesReject = (url) => {
        if(showModal?.Reason?.trim() !== "") {
            setErrorReason("");
            const param = {
                "transmit": "broadcast",
                "url": "order_cancelled",
                "request" :{
                    "order_id" : showModal?.Data?.id,
                    "cancel_reason" : showModal?.Reason?.trim(),
                    "order_status" : url === "Order Accept" ? "RETURN" : showModal?.Data?.cancel_stage,
                },
                'DeviceId':device_id,
            }
            wsSend_request(websocket, param);
        } else {
            setErrorReason("Please enter reason")
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url == "order_cancelled") {
            setShowModal({
                ...showModal,
                open: false,
                title: "",
                modalType: "",
                Reason: "",
                Data: {}
            });
            ReturnReplaceOrder();
        };
    }, [ ClearFormSet ]);

    return (<section className="Main_section">
        <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group>
                            <div className="datepickField min">
                                <DatePicker
                                    selected={FromDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    onChange={(date) => DateStartGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={FromDate !== "" ? true : false}
                                    name="statDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" >
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker
                                    selected={ToDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    minDate={startDate}
                                    maxDate={endDate}
                                    onChange={(date) => DateEndGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={ToDate !== "" ? true : false}
                                    name="ToDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "15%" }}>
                        <select className="form-control activestatusselet pe-4" id="columnfullwith" value={selectRegion} onChange={(e) => selectRegionFunction(e)}>
                            <option value="">Select Region</option>
                            {Regionlistall?.map((elm, index) => {
                                return(<option value={elm?.id} key={index}>{elm?.region_name}</option>)
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button className="btn exportbtn" disabled={ToDate === "" || FromDate === ""} style={{ width: "auto" }} onClick={() => CommonExport("order_list", { "FromDate": null, "ToDate": null, "FromInv": FromDate, "ToInv": ToDate, "status": "RETURN,REPLACE", "region_id": selectRegion,"search": userSearch.trim(), "order_type": orderSource })}>
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <DataTableFilter
            filterType={""}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            searchType={"Order Id, Name, Invoice ID, Customer Code"}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[14]?.Edit) ? true : false}
            searchdisable={true}
            orderSource={orderSource}
            callSelectSource={setOrderSource}
            ButtonSet={{
                buttontitle: "Return & Replace",
                function: cmnFnct
            }}
        />
        <div className="tableView orderlistTable gstreporttableadded">
            <Scrollbars
                style={{ height: ReturnReplaceOrderList?.pagination?.total_records > 10 ? "calc(100vh - 245px)" : "calc(100vh - 215px)" }}
                renderView={props => <div {...props} className="view" />}
                renderTrackHorizontal={renderTrack}
                className="ScrollbarsSidebar"
            >
                <Table responsive bordered hover >
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNo</th>
                            <th className={sortingfor === "order_code" ? "active" : sortingfor === "-order_code" ? "inactive" : ""} onClick={() => CommonSortingFct("order_code")}>Order Id</th>
                            <th className={sortingfor === "customer_identity" ? "active" : sortingfor === "-customer_identity" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_identity")}>Customer Code</th>
                            <th className={sortingfor === "created_at" ? "active" : sortingfor === "-created_at" ? "inactive" : ""} onClick={() => CommonSortingFct("created_at")}>Order Date</th>
                            <th className={sortingfor === "customer_name" ? "active" : sortingfor === "-customer_name" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_name")}>Name</th>
                            <th className={sortingfor === "invoice_code" ? "active" : sortingfor === "-invoice_code" ? "inactive" : ""} onClick={() => CommonSortingFct("invoice_code")}>Invoice ID</th>
                            <th className={sortingfor === "invoice_at" ? "active" : sortingfor === "-invoice_at" ? "inactive" : ""} onClick={() => CommonSortingFct("invoice_at")}>Invoice Date</th>
                            <th className={sortingfor === "courier_name" ? "active" : sortingfor === "-courier_name" ? "inactive" : ""} onClick={() => CommonSortingFct("courier_name")}>Transporter</th>
                            <th className={sortingfor === "updated_at" ? "active" : sortingfor === "-updated_at" ? "inactive" : ""} onClick={() => CommonSortingFct("updated_at")}>Pending Since </th>
                            <th>Criticality</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ReturnReplaceOrderList?.list?.map((elm, index) => {
                            const get_color = getColor(elm);
                            return (
                                <React.Fragment key={index}>
                                    <tr key={index}>
                                        <td style={{ width: '10px' }}>
                                            <button
                                                className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                                onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={activeTableDetails === elm?.id ? true : false}
                                            >
                                                <Image src={MoreIcon} alt="more icon" />
                                            </button>
                                        </td>
                                        <td>
                                            <Link className="btn-link btn-link-color text-decoration-none" onClick={() => showOrder(elm?.id)}>
                                                {GetCurrentTableSRNo(index, ReturnReplaceOrderList?.pagination?.current_page, ReturnReplaceOrderList?.pagination?.record_limit)}
                                            </Link>
                                        </td>
                                        <td>
                                            <b
                                                // onClick={() => OrderDetailsModal(elm?.id)} 
                                                style={{ cursor: "auto" }}
                                                className="tableBolanchor">{elm?.order_code}</b>
                                        </td>
                                        <td>
                                            {elm?.customer_identity === null ? "-" : elm?.customer_identity}
                                        </td>
                                        <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                        <td>{elm?.customer_name}</td>
                                        <td>
                                            {elm?.invoice_code === "OPEN" ? "-" : <b className="tableBolanchor" onClick={(e) => callInvoiceModal(e, elm?.id)}>{elm?.invoice_code}</b>}
                                        </td>
                                        <td>{CommonDateTime(elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                        <td>{elm?.courier_name ? elm?.courier_name : "-"}</td>
                                        <td>{TimeSense(elm?.updated_at)}</td>
                                        <td>
                                            <span className={`px-2 py-1 rounded ${get_color.color_code}`} >{get_color.text}</span>
                                        </td>
                                        {/* <td>{elm?.updated_at?.split(" ")[0] === elm?.created_at?.split(" ")[0] ? TimeSense(elm?.updated_at) : TimeSense(elm?.created_at)}</td> */}
                                        <td>
                                            {CommonStatus(elm?.order_status)}
                                        </td>
                                        <td>
                                            {((elm?.order_type === "AO" && elm?.uni_code !== null) && (elm?.order_status === "RETURN_REQUEST" || elm?.order_status === "REPLACE_REQUEST")) ? (<div style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                                                <Button variant="primary" size="sm" onClick={() => ModalAcceptOpen(elm, "Accept")}>Accept</Button>
                                                <Button variant="danger" size="sm"  className="btn" onClick={() => ModalAcceptOpen(elm, "Reject")}>Reject</Button>
                                            </div>) : ("-")}
                                        </td>
                                    </tr>
                                    <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                        <tr className="tablecollapsewraps" id={"tablecollpase" + elm?.id}>
                                            <td colSpan="12" >
                                                <Table bordered hover className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Order Source</th>
                                                            <td>{elm?.order_source === null ? "-" : elm?.order_source}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Quantity</th>
                                                            <td>{elm?.qty}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Order Value</th>
                                                            <td>{AmountNumberFormat(elm?.total)}</td>

                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>SKU Type</th>
                                                            <td>{elm?.category}</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <th style={{ width: "20%" }}>Status</th>
                                                            <td>
                                                                {CommonStatus(elm?.order_status)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Invoice Date</th>
                                                            <td>{CommonDateTime(elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                                        </tr> */}
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Attachments</th>
                                                            <td>
                                                                {elm?.attachments === null ? "-" : (<ul className="fileuploadListytable">
                                                                    {elm?.attachments?.map((elm, index) => {
                                                                        return (<li key={index}>
                                                                            <a href={process.env.REACT_APP_BASE_URL + "file/" + elm?.view_file_url} download={elm?.attachment_name} target="__blank"><i className="bi bi-file-earmark-text"></i> {elm?.attachment_name}  <i className="bi bi-download downloadIcomn"></i></a>
                                                                        </li>)
                                                                    })}
                                                                </ul>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Region</th>
                                                            <td>{GetRegionName(elm?.region_id)}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </Collapse>
                                </React.Fragment>
                            )
                        })}
                        {ReturnReplaceOrderList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="11">Return replace order list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {ReturnReplaceOrderList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={currentPage}
                paginationLength={ReturnReplaceOrderList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        <OrderDetailsViewer
            OrderViewerModal={OrderViewerModal}
            setOrderViewerModal={setOrderViewerModal}
        />
        <OrderStatusModel setOrderNewModel={setOrderNewModel} OrderNewModel={OrderNewModel} />

        {showModal?.open && (<Modal show={showModal?.open} onHide={onClose} className='commonModal' centered>
            <Modal.Header className='border-bottom-0 bb-0 pb-0' closeButton>
                <Modal.Title className='h5'>{showModal?.title} <span className='text-danger' >*</span></Modal.Title>
            </Modal.Header>
            <div className='p-3'>
                <FormControl 
                    value={showModal?.Reason} 
                    onChange={(e) => {
                        setShowModal({
                            ...showModal,
                            Reason: e?.target?.value,
                        });
                    }}
                    placeholder='Enter a reason' as='textarea' 
                />
                {errorReason !== "" &&(<small className='text-danger' >{errorReason}</small>)}
            </div>
           <div className='d-flex  px-3 pb-3'>
                <button className='btn button-1 w-auto ms-auto' onClick={() => OrderSuccesReject(showModal?.title)}>Submit</button>
           </div>
        </Modal>)}
    </section>)
}

export default ReturnAndReplace;