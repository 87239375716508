/* eslint-disable */
import React, { useState } from "react";
import { Table, Button, Form, Badge } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import Multiselect from 'multiselect-react-dropdown';

const FranchiseStockLevel = (props) => {
    const { RouteName } = props;
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ OrderViewerModal, setOrderViewerModal ] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    const BrandsArray = [ "EMotorad" , "Accessories", "X press" ];
    const [ selectedValue, setselectedValue ] = useState("");
    const [ FilterBy , setFilterBy ] = useState("all");

    const onSelect = (selectedList, selectedItem) => {
        
    };

    // remove permsissions
    const onRemove = (selectedList, removedItem) => {
        
    };

    // active supplier
    // const AllowCategoryGet = (data_, type, booleancheck) => {
    //     setShowModal({
    //         ...showModal,
    //         open: !showModal?.open,
    //         title: "Are you sure?",
    //         subtitle: "Active Category",
    //         description: `Do you really want to ${booleancheck ? "remove " : ""} ${type.split("_")[1]} to <h5>${data_?.name}</h5>`,
    //         modalType: "not form",
    //         button: "Yes",
    //         Data: data_
    //     });
    // }; 

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
          display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    return(<section className="Main_section">
        <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group className="mb-3" >
                            <Form.Label>Franchise Type</Form.Label>
                            <select className="seletcustom">
                                <option>EM Shunya</option>
                                <option>EM Chanakya</option>
                                <option>Others</option>
                            </select>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" >
                            <Form.Label>Franchise</Form.Label>
                            <div className="selectMultiselect">
                                <Multiselect
                                    options={BrandsArray} // Options to display in the dropdown
                                    selectedValues={selectedValue} // Preselected value to persist in dropdown
                                    onSelect={onSelect} // Function will trigger on select event
                                    onRemove={onRemove} // Function will trigger on remove event
                                    displayValue={"value"} // Property name to display in the dropdown options
                                    singleSelect={false} // only single category select / for multiple category select remove singleSelect 
                                    isObject={false}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" >
                            <Form.Label>Filter By</Form.Label>
                            <select className="seletcustom" value={FilterBy} onChange={(e) => setFilterBy(e.target.value)}>
                                <option value="all">All</option>
                                <option value="on">On</option>
                                <option value="from">From</option>
                            </select>
                        </Form.Group>
                    </div>
                    {FilterBy === "from" ? (<>
                        <div className="col-md-3">
                            <Form.Group className="mb-3" >
                                <Form.Label>From</Form.Label>
                                <div className="datepickField">
                                    <DatePicker 
                                        // selected={DateSelect} 
                                        // onChange={(date) => setDateSelect(date)} 
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth 
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="Enter Date"
                                        // maxDate={newDate}
                                        autoComplete="off"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3" >
                            <Form.Group className="mb-3" >
                                <Form.Label>To</Form.Label>
                                <div className="datepickField">
                                    <DatePicker 
                                        // selected={DateSelect} 
                                        // onChange={(date) => setDateSelect(date)} 
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth 
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="Enter Date"
                                        // maxDate={newDate}
                                        autoComplete="off"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                    </>) : FilterBy === "on" ? (<>
                        <div className="col-md-3">
                            <Form.Group className="mb-3" >
                                <Form.Label>Select On Date</Form.Label>
                                <div className="datepickField">
                                    <DatePicker 
                                        // selected={DateSelect} 
                                        // onChange={(date) => setDateSelect(date)} 
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth 
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="Enter Select On Date"
                                        // maxDate={newDate}
                                        autoComplete="off"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                    </>) : ("")}
                    <div className="col-md-3">
                        <Form.Group  >
                            <Form.Label>&nbsp;</Form.Label>
                            <Button className="Addbtn" id="GetDetailsbntn" style={{ marginTop: "0px" }}>Get Details</Button>
                            <Button className="filterBlackbtn" id="GetDetailsbntn" style={{ marginTop: "0px" }}>Clear</Button>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </div>
        <DataTableFilter 
            filterType={""}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />
        
        <div className="tableView">
            <Scrollbars 
                style={{ height: "calc(100vh - 260px)" }} 
                renderView={props => <div {...props} className="view"/>}
                renderTrackHorizontal={renderTrack}
                className="ScrollbarsSidebar"
            >
                <Table responsive bordered hover >
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th>Shoppe Code</th>
                            <th>Product Code</th>
                            <th>Product Name</th>
                            <th>Opening Stock</th>
                            <th>Stock Inward</th>
                            <th>Sale Qty</th>
                            <th>Balance Stock</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>001</td>
                            <td>XFactor</td>
                            <td>X1 Bumblebee Yellow- XX02EB1000</td>
                            <td>2</td>
                            <td>0</td>
                            <td>0</td>
                            <td>2</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>001</td>
                            <td>XFactor</td>
                            <td>X1 Bumblebee Yellow- XX02EB1000</td>
                            <td>2</td>
                            <td>0</td>
                            <td>0</td>
                            <td>2</td>
                        </tr>
                        <tr className="totaltd">
                            <th colSpan={4} style={{ textAlign: "center" }}>Page Total</th>
                            <th>87</th>
                            <th>0</th>
                            <th>0</th>
                            <th>87</th>
                        </tr>
                        <tr className="totaltd">
                            <th colSpan={4} style={{ textAlign: "center" }}>Grand Total</th>
                            <th>135</th>
                            <th>0</th>
                            <th>0</th>
                            <th>135</th>
                        </tr>
                    </tbody>
                </Table>
            </Scrollbars>
        </div>
        <OrderDetailsViewer 
            OrderViewerModal={OrderViewerModal} 
            setOrderViewerModal={setOrderViewerModal} 
        />
    </section>)
}

export default FranchiseStockLevel;