/* eslint-disable */
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Table, Collapse, Form, Badge, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { useNavigate, Link } from "react-router-dom";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../../Common/CommonPagination";
import { WebSocketContext } from "../../../App";
import Select from 'react-select';
import CommonDateTime from "../../Common/CommonDateTime";
import MoreIcon from '../../../assets/img/more_options.png';
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonExport from "../../Common/CommonExport";
import SendMailModal from "../../Common/SendMailModal";

const StoreDetailsReport = (props) => {
    const { RouteName } = props;
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { selfInfo, device_id, storedDetailsReportList, accessoriesReportList, wareHouselistall, productlistall, StockInwardList } = useSelector((state) => state.adminReducers);
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("");
    const [orderType, setOrderType] = useState("");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectWarehouse, setselectWarehouse] = useState("");
    const [selectProduct, setselectProduct] = useState("");
    const [activeTableDetails, setActiveTableDetails] = useState("");
    const [FilterOpen, setFilterOpen] = useState(false);
    const [Tab, setStoreTab] = useState("bike");
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const reportListData = Tab === "bike" ? storedDetailsReportList : accessoriesReportList;
    const optionsWarehouse = wareHouselistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: `${item?.warehouse_name} (${item?.warehouse_code}) - (${item?.display_name})` }));
    const optionsProducts = productlistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.product_name }));
    const today = new Date();
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    const [ sendEmailModal, setsendEmailModal ] = useState({
        open: false,
        title: ""
    });

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            // setEndDate(date)
            setToDate(date);
        }
    };

    // get warehouse code
    const GetWareHouseCode = (warehouse_id) => {
        return wareHouselistall?.filter((elm) => elm?.id === warehouse_id)[0]?.warehouse_name;
    };

    // get all city list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[4]?.Edit || PermissionsAccess?.Store[4]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "product_list",
                "request": {
                    "status": "",
                    "limit": 5000,
                    "page_no": 1,
                    "search": ""
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all city list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[4]?.Edit || PermissionsAccess?.Store[4]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "warehouse_list",
                "request": {
                    "status": "",
                    "limit": 5000,
                    "page_no": 1,
                    "search": ""
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get product list
    useEffect(() => {
        if (websocket) {
            callPayloadReport(Tab, currentPage);
        }
    }, [websocket, userSearch, userType, orderType, currentPage, userLimit, selectWarehouse, selectProduct, FromDate, ToDate]);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // wareehouse select
    const WareHouseSelect = (value) => {
        setselectWarehouse(value?.value);
        setCurrentPage(1)
    };

    // Product select
    const ProductSelect = (value) => {
        setselectProduct(value?.value);
        setCurrentPage(1)
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    const setTab = (item) => {
        setStoreTab(item);
        callPayloadReport(item, 1);
        setCurrentPage(1);
        setUserLimit(10);
    };
    
    const callPayloadReport = (Tab, currentPage) => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[4]?.Edit || PermissionsAccess?.Store[4]?.View)) {
            let param;
            if (Tab === "stock_inward") {
                param = {
                    "transmit": "broadcast",
                    "url": "stock_inward_list",
                    "request": {
                        "limit": userLimit,
                        "page_no": currentPage,
                        "warehouse_id": selectWarehouse,
                        "product_id": selectProduct,
                        "order_by": "-updated_at"
                    },
                    "DeviceId": device_id
                };
            } else {
                param = {
                    "transmit": "broadcast",
                    "url": "store_frame_list",
                    "request": {
                        "status": userType,
                        "limit": userLimit,
                        "page_no": currentPage,
                        "search": userSearch.trim(),
                        "from_date": null,
                        "to_date": null,
                        "order_type": orderType,
                        "warehouse_id": selectWarehouse,
                        "product_id": selectProduct,
                    },
                    "DeviceId": device_id
                };
                if (Tab !== "bike") {
                    param.url = "store_accessories_list";
                }
                if (FromDate !== "" && ToDate !== "") {
                    param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                    param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
                }
            };
            wsSend_request(websocket, param);
        };
    }


    // get product details
    const GetProductData = (id) => {
        const ProductlistallCurrent = productlistall?.filter((elm) => elm?.id === id)[0];
        return {
            ProductName: ProductlistallCurrent?.product_name + ` - (${ProductlistallCurrent?.product_code})`,
            PurchasePrice: ProductlistallCurrent?.is_bike ? ProductlistallCurrent?.demo_rate : ProductlistallCurrent?.franchise_rate
        };
    };

    // send email function
    const SendEmailFunction = () => {
        setsendEmailModal({
            ...sendEmailModal,
            open: !sendEmailModal?.open,
            title: "Send Mail"
        });
    };

    return (<section className="Main_section StockDetailsReportPage">
        <div className="MainHeader">
            <ul className="MainHeader_Tab" style={{ width: "100%" }}>
                <li className={Tab === "bike" ? "active" : ""} onClick={() => setTab("bike")}>Bike</li>
                {/* <li className={Tab === "accessories" ? "active" : ""} onClick={() => setTab("accessories")}>Accessories</li> */}
                <li className={Tab === "stock_inward" ? "active" : ""} onClick={() => setTab("stock_inward")}>Stock Inward</li>

                {((selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.StockDetailsReportSendMailActive) && Tab === "bike") && (<button type="button" className="btn sendemailstocks" onClick={() => SendEmailFunction()}>
                    <i className="bi bi-envelope-at"></i> Send Mail
                </button>)}
            </ul>
        </div>
        {Tab === "stock_inward" ? (<div className="filtr_Form" style={{ borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group className="mb-3" >
                            {/* <Form.Label>Warehouse</Form.Label> */}
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    onChange={(e) => WareHouseSelect(e)}
                                    isClearable
                                    options={optionsWarehouse}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Warehouse"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" id="mb-3">
                            {/* <Form.Label>Products</Form.Label> */}
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    onChange={(e) => ProductSelect(e)}
                                    isClearable
                                    options={optionsProducts}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Products"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <button className="btn exportbtn" style={{ width: "auto", float: "left" }} onClick={() => CommonExport("export_stock_inward", { "product_id": selectProduct, "warehouse_id": selectWarehouse, "limit": userLimit })}>
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>) : (<div className="filtr_Form" style={{ borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group>
                            <div className="datepickField min">
                                <DatePicker
                                    selected={FromDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    onChange={(date) => DateStartGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={FromDate !== "" ? true : false}
                                    name="statDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" >
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker
                                    selected={ToDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    minDate={startDate}
                                    maxDate={endDate}
                                    onChange={(date) => DateEndGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={ToDate !== "" ? true : false}
                                    name="ToDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" >
                            {/* <Form.Label>Warehouse</Form.Label> */}
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    onChange={(e) => WareHouseSelect(e)}
                                    isClearable
                                    options={optionsWarehouse}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Warehouse"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" >
                            {/* <Form.Label>&nbsp;</Form.Label> */}
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 100 }}
                                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                    {FilterOpen ? "Filter Hide" : "Filter Show"}
                                </Tooltip>}
                            >
                                <button
                                    className="filtercollapsebtn"
                                    onClick={() => setFilterOpen(!FilterOpen)}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={FilterOpen}
                                >
                                    {FilterOpen ? (<i className="bi bi-funnel-fill"></i>) : (<i className="bi bi-funnel"></i>)}
                                </button>
                            </OverlayTrigger>
                            <button className="btn exportbtn" disabled={ToDate === "" || FromDate === ""} style={{ width: "auto", float: "right" }} onClick={() => CommonExport("export_stock_frame", { "FromDate": FromDate, "ToDate": ToDate, "search": userSearch.trim(), "product_id": selectProduct, "warehouse_id": selectWarehouse, "status": userType, "order_type": orderType, "limit": userLimit })}>
                                <i className="bi bi-filetype-xlsx"></i>
                                Export
                            </button>
                        </Form.Group>
                    </div>
                </div>
            </div>
            <Collapse in={FilterOpen}>
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-3">
                            <Form.Group className="mb-3" id="mb-3">
                                {/* <Form.Label>Products</Form.Label> */}
                                <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                    <Select
                                        onChange={(e) => ProductSelect(e)}
                                        isClearable
                                        options={optionsProducts}
                                        className="godown-select-container"
                                        classNamePrefix="godown-select"
                                        placeholder="Select Products"
                                        styles={customStyles}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group className="mb-3" id="mb-3">
                                {/* <Form.Label>Status</Form.Label> */}
                                <select className="form-control" value={userType} onChange={(e) => { setUserType(e?.target?.value); setCurrentPage(1) }}>
                                    <option value="">Select Status</option>
                                    <option value="SOLD">Sold</option>
                                    <option value="UNSOLD">Un-Sold</option>
                                </select>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group className="mb-3" id="mb-3">
                                {/* <Form.Label>User Type</Form.Label> */}
                                <select className="form-control" value={orderType} onChange={(e) => { setOrderType(e?.target?.value); setCurrentPage(1) }}>
                                    <option value="">All User Type</option>
                                    <option value="super_admin">Admin</option>
                                    <option value="Franchise">Franchise</option>
                                </select>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>)}

        <div className={Tab === "stock_inward" ? "filter_stock_inward active" : "filter_stock_inward"}>
            {Tab === "stock_inward" ? (<DataTableFilter
                filterType={Tab === "bike" ? "Frame Number" : "Accessories"}
                searchType={"Frame Number"}
                userSearch={userSearch}
                setUserSearch={setUserSearch}
                userLimit={userLimit}
                setUserLimit={setUserLimit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                buttonvisible={false}
                searchdisable={false}
                ActiveInactiveFilter={false}
            />) : (<DataTableFilter
                filterType={Tab === "bike" ? "Frame Number" : "Accessories"}
                searchType={"Frame Number"}
                userSearch={userSearch}
                setUserSearch={setUserSearch}
                userLimit={userLimit}
                setUserLimit={setUserLimit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                buttonvisible={false}
                searchdisable={true}
                ActiveInactiveFilter={false}
            />)}
        </div>
        <div className="tableView">
            {Tab === "stock_inward" ? (<>
                <Scrollbars
                    style={{
                        height: FilterOpen === true ?
                            StockInwardList?.pagination?.total_records > 10 ? "calc(100vh - 253px)" : "calc(100vh - 265px)" :
                            StockInwardList?.pagination?.total_records > 10 ? "calc(100vh - 253px)" : "calc(100vh - 265px)"
                    }}
                    renderView={props => <div {...props} className="view" />}
                    renderTrackHorizontal={renderTrack}
                    className="ScrollbarsSidebar"
                >
                    <Table responsive bordered hover >
                        <thead>
                            <tr>
                                <th>SNo</th>
                                <th>FsCode</th>
                                <th>Purchase Price</th>
                                <th>Batch No</th>
                                <th>Mfg Date</th>
                                <th>Expiry Date</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {StockInwardList?.list?.map((elm, index) => {
                                return (<React.Fragment key={index}>
                                    <tr>
                                        <td>
                                            {GetCurrentTableSRNo(index, StockInwardList?.pagination?.current_page, StockInwardList?.pagination?.record_limit)}
                                        </td>
                                        <td>{GetProductData(elm?.product_id)?.ProductName}</td>
                                        <td>{GetProductData(elm?.product_id)?.PurchasePrice}</td>
                                        <td>{elm?.batch_no}</td>
                                        <td>{CommonDateTime(elm.mfg_date, "LL", "show")}</td>
                                        <td>{CommonDateTime(elm.exp_date, "LL", "show")}</td>
                                        <td>{elm?.qty}</td>
                                    </tr>
                                </React.Fragment>)
                            })}
                            {(StockInwardList?.list?.length === 0 || StockInwardList?.list?.length === undefined) && <tr style={{ textAlign: "center" }} ><td colSpan="9">Stock details report list not found</td></tr>}
                        </tbody>
                    </Table>
                </Scrollbars>
                {/* pagination */}
                {StockInwardList?.pagination?.total_records > 10 && (<CommonPagination
                    currentPage={currentPage}
                    paginationLength={StockInwardList?.pagination}
                    currentFunction={currentFunction}
                />)}
            </>) : (<>
                <Scrollbars
                    style={{
                        height: FilterOpen === true ?
                            reportListData?.pagination?.total_records > 10 ? "calc(100vh - 346px)" : "calc(100vh - 304px)" :
                            reportListData?.pagination?.total_records > 10 ? "calc(100vh - 304px)" : "calc(100vh - 304px)"
                    }}
                    renderView={props => <div {...props} className="view" />}
                    renderTrackHorizontal={renderTrack}
                    className="ScrollbarsSidebar"
                >
                    <Table responsive bordered hover >
                        <thead>
                            <tr>
                                <th></th>
                                <th>SNo</th>
                                <th>{Tab === "bike" ? "Frame Number" : "Serial Number"}</th>
                                <th>Warehouse Name</th>
                                <th>Added On</th>
                                <th>Sold On</th>
                                <th>Order No</th>
                                <th>Billing No</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportListData?.list?.map((elm, index) => {
                                return (<React.Fragment key={index}>
                                    <tr>
                                        <td style={{ width: '10px' }}>
                                            <button
                                                className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                                onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={activeTableDetails === elm?.id ? true : false}
                                            >
                                                <Image src={MoreIcon} alt="more icon" />
                                            </button>
                                        </td>
                                        <td>
                                            {GetCurrentTableSRNo(index, reportListData?.pagination?.current_page, reportListData?.pagination?.record_limit)}
                                        </td>
                                        <td>{Tab === "bike" ? elm?.frame_number : elm?.serial_number}</td>
                                        <td>{GetWareHouseCode(elm?.warehouse_id)}</td>
                                        <td>{CommonDateTime(elm.created_at, "LL", "show")}</td>
                                        <td>{CommonDateTime(elm.order_at, "LL", "show")}</td>
                                        <td>
                                            {elm?.order_code === null ? "-" : elm?.order_code}
                                        </td>
                                        <td>{(elm?.bill_no === null || elm?.bill_no === "None") ? "-" : elm?.bill_no}</td>
                                        <td>{elm?.status === "UNSOLD" ? <Badge bg="danger">Un-Sold</Badge> : <Badge bg="success">Sold</Badge>}</td>
                                    </tr>
                                    <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                        <tr className="tablecollapsewraps" id={"tablecollpase" + elm?.id}>
                                            <td colSpan="8" >
                                                <Table bordered hover className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>User Type</th>
                                                            <td>{elm?.order_type === null ? "-" : elm?.order_type}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Battery Number</th>
                                                            <td>{elm?.battery_number}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Motor Number</th>
                                                            <td>{elm?.motor_number}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Charger Number</th>
                                                            <td>{elm?.charger_number}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </Collapse>
                                </React.Fragment>)
                            })}
                            {reportListData?.list?.length === 0 && (<tr style={{ textAlign: "center" }}>
                                <td colSpan={9}>Stock details report list not found</td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Scrollbars>
                {/* pagination */}
                {reportListData?.pagination?.total_records > 10 && (<CommonPagination
                    currentPage={currentPage}
                    paginationLength={reportListData?.pagination}
                    currentFunction={currentFunction}
                />)}
            </>)}
        </div>
        
        {/* send mail for Stock Details Report modal */}
        {sendEmailModal?.open && (<SendMailModal sendEmailModal={sendEmailModal} setsendEmailModal={setsendEmailModal} />)}
    </section>)
}

export default StoreDetailsReport;