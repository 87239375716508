

/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge, Collapse, Image, Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../../Common/CommonPagination";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import { Link, useNavigate } from "react-router-dom";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonewModel from "../../Common/CommonewModel";
import CommonExport from "../../Common/CommonExport";

const AccountLedgerReport = (props) => {
    const navigate = useNavigate();
    const Dispatch = useDispatch();
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("all");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [show, setShow] = useState(null)
    const today = new Date();
    const { device_id, EInvoiceFailure, DNCNLedgerList } = useSelector((state) => state.adminReducers);
    const [OrderViewerModal, setOrderViewerModal] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    const [showModalNew, setShowModalNew] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    const showTable = (value) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Summary Details",
            subtitle: "Table",
            // description: `Are you sure! You want to confirm this transaction?</h5>`,
            modalType: "form",
            // button: "Yes",
            Data: value
        });
    }

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            // setEndDate(date)
            setToDate(date);
        }
    };

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };
    // get order_list all
    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "dncn_ledger",
            "request": {
                "limit": userLimit,
                "page_no": currentPage,
                "from_date": null,
                "to_date": null,
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    }, [websocket, currentPage, userLimit, FromDate, ToDate]);

    // get frachise data
    const GetFranchiseData = (id) => {
        const franchise = DNCNLedgerList?.franchise?.filter((elm) => elm?.id === id)[0];
        return {
            FranchiseCode: franchise?.franchise_code,
            FranchiseName: franchise?.customer_name,
        };
    };

    return (<section className="Main_section">
        <div className="row">
            <div className="col-md-3">
                <Form.Group>
                    <div className="datepickField min">
                        <DatePicker
                            selected={FromDate}
                            startDate={FromDate}
                            endDate={ToDate}
                            onChange={(date) => DateStartGet(date)}
                            dateFormat="dd/MM/yyyy"
                            maxDate={today}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Enter From Date"
                            isClearable={FromDate !== "" ? true : false}
                            name="statDate"
                            autoComplete="off"
                        />
                    </div>
                </Form.Group>
            </div>
            <div className="col-md-3" >
                <Form.Group>
                    <div className="datepickField">
                        <DatePicker
                            selected={ToDate}
                            startDate={FromDate}
                            endDate={ToDate}
                            minDate={startDate}
                            maxDate={endDate}
                            onChange={(date) => DateEndGet(date)}
                            dateFormat="dd/MM/yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Enter To Date"
                            isClearable={ToDate !== "" ? true : false}
                            name="ToDate"
                            autoComplete="off"
                        />
                    </div>
                </Form.Group>
            </div>
            <div className="col-md-3">
                <button className="btn exportbtn" disabled={ToDate === "" || FromDate === ""} style={{ width: "auto" }} onClick={() => CommonExport("dncn_ledger", { "FromDate": FromDate, "ToDate": ToDate, "limit": userLimit})}>
                    <i className="bi bi-filetype-xlsx"></i>
                    Export
                </button>
            </div>
        </div>
        <DataTableFilter
            searchType={"Invoice ID."}
            filterType={"Account Ledger Report"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={false}
        />
        <div className="tableView">
            <Scrollbars
                style={{ height: parseInt(DNCNLedgerList?.pagination?.total_records) === DNCNLedgerList?.list?.length ? "calc(100vh - 206px)" : DNCNLedgerList?.pagination?.total_records > 10 ? "calc(100vh - 225px)" : "calc(100vh - 206px)" }}
                renderView={props => <div {...props} className="view" />}
                renderTrackHorizontal={renderTrack}
                className="ScrollbarsSidebar"
            >
                <Table responsive bordered hover >
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th>Franchise Code</th>
                            <th>Name</th>
                            <th>Opening Balance</th>
                            <th>Credit Amount</th>
                            <th>Debit  Amount</th>
                            <th>Closing Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {DNCNLedgerList?.list?.map((item, index) => {
                            return (<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, DNCNLedgerList?.pagination?.current_page, DNCNLedgerList?.pagination?.record_limit)}
                                </td>
                                <td>
                                    <Link className="btn-link btn-link-color text-decoration-none" onClick={() => showTable(item)}>
                                        <b className="tableBolanchor">{GetFranchiseData(item?.franchise_id)?.FranchiseCode}</b>
                                    </Link>
                                </td>
                                {/* <td onClick={() => showTable(item)}>{GetFranchiseData(item?.franchise_id)?.FranchiseCode}</td> */}
                                <td>{GetFranchiseData(item?.franchise_id)?.FranchiseName}</td>
                                <td>0</td>
                                <td>{AmountNumberFormat(item.total_credit)}</td>
                                <td>{AmountNumberFormat(item.total_debit)}</td>
                                <td>{AmountNumberFormat(parseFloat(item.total_credit) - parseFloat(item.total_debit))}</td>
                            </tr>)
                        })}
                        {DNCNLedgerList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">Account ledger report list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {DNCNLedgerList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={currentPage}
                paginationLength={DNCNLedgerList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />)}
        <OrderDetailsViewer
            OrderViewerModal={OrderViewerModal}
            setOrderViewerModal={setOrderViewerModal}
        />
        {show !== null && (
            <Modal show={show !== null} className="errorDetailsModal" onHide={() => setShow(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Error Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflow: "auto" }}>
                    <p dangerouslySetInnerHTML={{ __html: responseText(show?.invoice_response) }}>
                    </p>
                </Modal.Body>
            </Modal>
        )}
    </section>)
}

export default AccountLedgerReport;

