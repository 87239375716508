/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Dashboard = () => {
    const [ Tab, setTab ] = useState("sales_revenue_report");
    const { selfInfo } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Dashboard?.DealerPerformance && PermissionsAccess?.Dashboard?.OrderFulfillment && PermissionsAccess?.Dashboard?.SalesRevenueReport)) {
            setTab("sales_revenue_report");
        } else if(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.DealerPerformance) {
            setTab("dealer_performance");
        } else if(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.OrderFulfillment) {
            setTab("order_fulfillment");
        } else if(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.SalesRevenueReport) {
            setTab("sales_revenue_report");
        } else if(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.DailyOrdersRGLKolkata) {
            setTab("daily_orders_RGL_kolkata");
        } else if(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.DailyOrdersRGLDelhi) {
            setTab("daily_orders_RGL_delhi");
        } else if(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.DailyOrdersRGLBangalore) {
            setTab("daily_orders_RGL_bangalore");
        } else if(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.AllOrderSummery) {
            setTab("all_orders_summary");
        };
    }, [ selfInfo, PermissionsAccess ]);

    return(<div className="Main_section dashboard_Main_section">
        {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Dashboard?.DealerPerformance || PermissionsAccess?.Dashboard?.OrderFulfillment || PermissionsAccess?.Dashboard?.SalesRevenueReport)) ? (<React.Fragment>
            <div className="MainHeader">
                <ul className="MainHeader_Tab">
                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.SalesRevenueReport) && (<li  className={Tab === "sales_revenue_report" ? "active" : ""} onClick={() => setTab("sales_revenue_report")}>Sales Revenue Report</li>)}
                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.DealerPerformance) && (<li  className={Tab === "dealer_performance" ? "active" : ""} onClick={() => setTab("dealer_performance")}>Dealer Performance</li>)}
                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.OrderFulfillment) && (<li  className={Tab === "order_fulfillment" ? "active" : ""} onClick={() => setTab("order_fulfillment")}>Order Fulfillment</li>)}
                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.DailyOrdersRGLKolkata) && (<li  className={Tab === "daily_orders_RGL_kolkata" ? "active" : ""} onClick={() => setTab("daily_orders_RGL_kolkata")}>Daily Orders RGL Kolkata</li>)}
                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.DailyOrdersRGLDelhi) && (<li  className={Tab === "daily_orders_RGL_delhi" ? "active" : ""} onClick={() => setTab("daily_orders_RGL_delhi")}>Daily Orders RGL Delhi</li>)}
                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.DailyOrdersRGLBangalore) && (<li  className={Tab === "daily_orders_RGL_bangalore" ? "active" : ""} onClick={() => setTab("daily_orders_RGL_bangalore")}>Daily Orders RGL Bangalore</li>)}
                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Dashboard?.AllOrderSummery) && (<li  className={Tab === "all_orders_summary" ? "active" : ""} onClick={() => setTab("all_orders_summary")}>All Orders Summary</li>)}
                </ul>
            </div>
            {Tab === "sales_revenue_report" ? (<div className="iframe_wrapper">
                <iframe width="100%" height="100%"  src="https://lookerstudio.google.com/embed/reporting/1006884a-34d4-4d92-b863-55664ed9eafc/page/p_v3nvvftbhd" frameBorder="0" style={{ border: "0px" }} allowFullScreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-downloads allow-popups-to-escape-sandbox"></iframe>
            </div>) 
            : Tab === "dealer_performance" ? (<div className="iframe_wrapper">
                <iframe width="100%" height="100%"  src="https://lookerstudio.google.com/embed/reporting/36377ba7-c97b-4855-a496-6e0fe1f9ffea/page/p3FzD" frameBorder="0" style={{ border: "0px" }} allowFullScreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-downloads allow-popups-to-escape-sandbox"></iframe>
            </div>) 
            : Tab === "order_fulfillment" ? (<div className="iframe_wrapper">
                <iframe width="100%" id="framesidss" height="100%" src="https://lookerstudio.google.com/embed/reporting/00fb1a2e-3e14-4623-8d43-931d3b78983c/page/uX7yD" frameBorder="0" style={{ border: "0px" }} allowFullScreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-downloads allow-popups-to-escape-sandbox"></iframe>
            </div>) : Tab === "daily_orders_RGL_kolkata" ? (<div className="iframe_wrapper">
                <iframe width="100%" id="framesidss" height="100%" src="https://lookerstudio.google.com/embed/reporting/9d9d8f1f-f679-4887-950d-2ae4a0b4d1f1/page/5jC5D" frameBorder="0" style={{ border: "0px" }} allowFullScreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-downloads allow-popups-to-escape-sandbox"></iframe>
            </div>) : Tab === "daily_orders_RGL_delhi" ? (<div className="iframe_wrapper">
                <iframe width="100%" id="framesidss" height="100%" src="https://lookerstudio.google.com/embed/reporting/21362896-ff5b-402a-b7f5-0b629f81a1b2/page/MLC5D" frameBorder="0" style={{ border: "0px" }} allowFullScreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-downloads allow-popups-to-escape-sandbox"></iframe>
            </div>) : Tab === "daily_orders_RGL_bangalore" ? (<div className="iframe_wrapper">
                <iframe width="100%" id="framesidss" height="100%" src="https://lookerstudio.google.com/embed/reporting/e2453d0f-bfab-4470-a0cf-3019c0433b98/page/xKG5D" frameBorder="0" style={{ border: "0px" }} allowFullScreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-downloads allow-popups-to-escape-sandbox"></iframe>
            </div>) : (<div className="iframe_wrapper">
                <iframe width="100%" id="framesidss" height="100%" src="https://lookerstudio.google.com/embed/reporting/01c2a58e-6bd8-48dd-96d6-3bf89cd4fdfd/page/dlQ6D" frameBorder="0" style={{ border: "0px" }} allowFullScreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-downloads allow-popups-to-escape-sandbox"></iframe>
            </div>)}
        </React.Fragment>) : (<div className="permissionCenter">
            <span className="errorMessageIcon"><i className="bi bi-ban"></i>&nbsp; You do not have permission to view the dashboard.</span>
        </div>)}
    </div>)
}

export default Dashboard;