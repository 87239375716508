/* eslint-disable */

import React, { useState } from "react";
import Logo from "../../assets/img/logo.png";
import { Link, useLocation } from "react-router-dom";
import { DASHBOARD_URL } from "./constant";
import Dropdown from 'react-bootstrap/Dropdown';
import CommonewModel from "../Common/CommonewModel";
import { useDispatch, useSelector } from "react-redux";
import { setGetMagicCode } from "../../redux/actions/adminActions";
import { Image } from "react-bootstrap";

const Header = (props) => {
    const { sidebarWidth } = props;
    const dispatch = useDispatch();
    const PageName = useLocation()?.pathname?.split("/")[1]?.replaceAll("-", " ");
    const { selfInfo, device_id } = useSelector((state) => state.adminReducers);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page title get on header
    const PageTitle = () => {
        const PageNameCheck = PageName === "e invoice details" ? "e-invoice details" : PageName === "e invoice cancel" ? "e-invoice cancel" : PageName === "e way bill report" ? "e-way bill report" : PageName;
        if(Location === DASHBOARD_URL || Location === "/") {
            return PageNameCheck ;
        } else {
            return PageNameCheck;
        }
    };

     // logout function
     const LogoutFnct = (e) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Are you sure?",
            subtitle: "Logout",
            description: `Do you really want to logout ?</h5>`,
            modalType: "not form",
            button: "Yes"
        });
    }
    const callBackErrorHandle = (e) =>{
        if(window.location.origin !== "http://localhost:3000" && window.location.origin !== "http://localhost:3001"){
            dispatch(setGetMagicCode(""));
        }
    }

    return(<div className="head_section">
        <div className="navLogo" style={{ width: `${sidebarWidth + 3}px`}}>
        {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Dashboard?.DealerPerformance || PermissionsAccess?.Dashboard?.OrderFulfillment || PermissionsAccess?.Dashboard?.SalesRevenueReport)) ? (<Link to={DASHBOARD_URL} className="nav-logo">
                <Image src={process.env.REACT_APP_LOGO}  alt="logo"  onErrorCapture={callBackErrorHandle} onError={callBackErrorHandle} />
            </Link>
        ) : (<Link className="nav-logo">
            <Image src={process.env.REACT_APP_LOGO}  alt="logo"  onErrorCapture={callBackErrorHandle} onError={callBackErrorHandle} />
        </Link>)}
        </div>
        <h4 className="pageTitle">{PageTitle()}</h4>
        <div className="dropdownHeader">
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                    <i className="bi bi-person-circle"></i>  
                    <div className="profilUser">
                        <h4>{selfInfo?.user?.full_name}</h4>
                        <small>{(selfInfo?.user?.user_type === 'regional_manager' || selfInfo?.user?.user_type === 'regional_sales_team_rm') ? "Regional Manager" : (selfInfo?.user?.user_type === 'regional_sales_team_asm') ? "Assistant Sales Manager" : selfInfo?.user?.user_type?.replaceAll("_", " ")}</small>
                    </div> 
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => LogoutFnct()}>Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
        <CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />
    </div>)
}

export default Header;