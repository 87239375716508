/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge, Image, Collapse, Modal, FormControl } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import { Link, useNavigate } from "react-router-dom";
import { TimeSense } from "../../Common/TimeSense";
import MoreIcon from '../../../assets/img/more_options.png';
import { getTableSorting } from "../../../redux/actions/adminActions";
import OrderStatusModel from "../../Common/OrderStatusModel";
import CommonExport from "../../Common/CommonExport";
import Select from 'react-select';
import CommonStatus from "../../Common/CommonStatus";
import { FBA_ORDER_DETAILS_LIST } from "../../Shared/constant";

const FbaOrdersList = (props) => {
    const { RouteName } = props;
    const Dispatch = useDispatch();
    const navigate = useNavigate();
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("all");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [activeTableDetails, setActiveTableDetails] = useState("");
    const [orderSource, setOrderSource] = useState("");
    const [ selectRegion, setSelectRegion ] = useState("");
    const [ selectWarehouse, setSelectWarehouse ] = useState("");
    const today = new Date();
    const { device_id, TbaOrderList, sortingfor, ClearFormSet, wareHouselistall, selfInfo } = useSelector((state) => state.adminReducers);
    const [showModal, setShowModal] = useState({
        open: false,
        title: "",
        modalType: "",
        Reason: "",
        Data: {}
    });
    const [ errorReason, setErrorReason ] = useState("");
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    useEffect(() => {
        Dispatch(getTableSorting(""))
    }, [])

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    const selectRegionFunction = (e) => {
        setSelectRegion(e.target.value);
        setCurrentPage(1);
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            // setEndDate(date)
            setToDate(date);
        }
    };

    // wareehouse select
    const WareHouseSelect = (value) => {
        if(value !== null) {
            setSelectWarehouse(value);
        } else {
            setSelectWarehouse("");
        };
    };

    // get order_list all
    useEffect(() => {
        if (websocket) {
            SOCancelled();
        }
    }, [websocket, userSearch, orderSource, selectWarehouse, selectRegion, userType, currentPage, userLimit, FromDate, ToDate, sortingfor]);

    const SOCancelled = () => {
        let param = {
            "transmit": "broadcast",
            "url": "fba_order_list",
            "request": {
                "status": "",
                "limit": userLimit,
                "page_no": currentPage,
                "search": userSearch.trim(),
                "from_date": null,
                "to_date": null,
                // "order_type": orderSource,
                // "region_id": selectRegion,
                // "warehouse_id": selectWarehouse?.value,
                "order_by": sortingfor,
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    };

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    const CommonSortingFct = (url) => {
        if (sortingfor === url) {
            Dispatch(getTableSorting("-" + url))
        } else {
            Dispatch(getTableSorting(url))
        };
    };

    // open order details modal
    const OrderDetailsModal = (order_code) => {
        navigate(`${FBA_ORDER_DETAILS_LIST}/${order_code}`);
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url == "order_cancelled") {
            setShowModal({
                ...showModal,
                open: false,
                title: "",
                modalType: "",
                Reason: "",
                Data: {}
            });
            SOCancelled();
        };
    }, [ ClearFormSet ]);

    return (<section className="Main_section">
        <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3" style={{ width: "23%" }}>
                        <Form.Group>
                            <div className="datepickField min">
                                <DatePicker
                                    selected={FromDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    onChange={(date) => DateStartGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={FromDate !== "" ? true : false}
                                    name="statDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "23%" }}>
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker
                                    selected={ToDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    minDate={startDate}
                                    maxDate={endDate}
                                    onChange={(date) => DateEndGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={ToDate !== "" ? true : false}
                                    name="ToDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "auto" }}>
                        <button 
                            className="btn exportbtn" 
                            // disabled={ToDate === "" || FromDate === ""} 
                            style={{ width: "auto" }} 
                            onClick={() => CommonExport("fba_order_list", "")}
                        >
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <DataTableFilter
            filterType={""}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            searchType={"Order Id, Channel name"}
        />
        <div className="tableView orderlistTable gstreporttableadded">
            <Scrollbars
                style={{ height: TbaOrderList?.pagination?.total_records > 10 ? "calc(100vh - 245px)" : "calc(100vh - 215px)" }}
                renderView={props => <div {...props} className="view" />}
                renderTrackHorizontal={renderTrack}
                className="ScrollbarsSidebar "
            >
                <Table responsive bordered hover >
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNo</th>
                            <th>Order Id</th>
                            <th>Channel Name</th>
                            <th>Order Date</th>
                            <th>SHIP To Pincode</th>
                            <th>SHIP To City</th>
                            <th>SHIP To State</th>
                            <th>Bill To Pincode</th>
                            <th>Bill To City</th>
                            <th>Bill To State</th>
                            <th>Marketplace Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {TbaOrderList?.list?.map((elm, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td style={{ width: '10px' }}>
                                            <button
                                                className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                                onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={activeTableDetails === elm?.id ? true : false}
                                            >
                                                <Image src={MoreIcon} alt="more icon" />
                                            </button>
                                        </td>
                                        <td>
                                            {/* <Link className="btn-link btn-link-color text-decoration-none" onClick={() => showOrder(elm?.id)}> */}
                                                {GetCurrentTableSRNo(index, TbaOrderList?.pagination?.current_page, TbaOrderList?.pagination?.record_limit)}
                                            {/* </Link> */}
                                        </td>
                                        <td>
                                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[16]?.Edit) ? (
                                                <b onClick={() => OrderDetailsModal(elm?.id)} className="tableBolanchor">{elm?.display_order_code}</b>
                                            ) : (<b className="tableBolanchor">{elm?.display_order_code}</b>)}
                                        </td>
                                        <td>{elm?.channel_name === null ? "-" : elm?.channel_name}</td>
                                        <td>{CommonDateTime(elm?.fba_created_at, "LL", "show")}</td>
                                        <td>{elm?.shipping_pincode !== null ? elm?.shipping_pincode : "-"}</td>
                                        <td>{elm?.shipping_city !== null ? elm?.shipping_city : "-"}</td>
                                        <td>{elm?.shipping_state !== null ? elm?.shipping_state : "-"}</td>
                                        <td>{elm?.bill_pincode !== null ? elm?.bill_pincode : "-"}</td>
                                        <td>{elm?.bill_city !== null ? elm?.bill_city : "-"}</td>
                                        <td>{elm?.bill_state !== null ? elm?.bill_state : "-"}</td>
                                        <td>{elm?.marketplace_status === null ? "-" : elm?.marketplace_status}</td>
                                    </tr>
                                    <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                        <tr className="tablecollapsewraps" id={"tablecollpase" + elm?.id}>
                                            <td colSpan="13" >
                                                <Table bordered hover className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>SHIP To Email</th>
                                                            <td>{(elm?.shipping_email !== null && elm?.shipping_email !== "") ? elm?.shipping_email : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>SHIP To Name</th>
                                                            <td>{elm?.shipping_name !== null ? elm?.shipping_name : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>SHIP To Phone</th>
                                                            <td>{elm?.shipping_phone !== null ? elm?.shipping_phone : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Bill To Email</th>
                                                            <td>{(elm?.bill_email !== null && elm?.bill_email !== "") ? elm?.bill_email : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Bill To Phone</th>
                                                            <td>{elm?.bill_phone !== null ? elm?.bill_phone : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Order Source</th>
                                                            <td>{elm?.source === null ? "-" : elm?.source}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </Collapse>
                                </React.Fragment>
                            )
                        })}
                        {TbaOrderList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="12">FBA Order list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {TbaOrderList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={currentPage}
                paginationLength={TbaOrderList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
    </section>)
}

export default FbaOrdersList;