/* eslint-disable */
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Table, Badge, Collapse, Image, Form } from 'react-bootstrap';
import DataTableFilter from "../../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate, Link } from "react-router-dom";
import { CREATE_FRANCHISE_USER_REPORT_URL } from "../../Shared/constant";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import { ClearFormSetFutios, getTableSorting } from "../../../redux/actions/adminActions";
import MoreIcon from '../../../assets/img/more_options.png';
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import Select from 'react-select';
import CommonewModel from "../../Common/CommonewModel";

const FranchiseUserReport = (props) => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { RouteName } = props;
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ ParentChildFilter, setParentChildFilter ] = useState(false);
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ selectState, setSelectState ] = useState("");
    const [ selectCity, setSelectCity ] = useState("");
    const [ FilterOpen, setFilterOpen ] = useState(false);
    const { FranchiseUserTypeList, device_id, selfInfo, citylistall, statelistall, sortingfor } = useSelector((state) => state.adminReducers);
    const optionsState = statelistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ ...item, key: item.id, value: item?.id, label: item?.state_name }));
    const optionsCity = citylistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ ...item, key: item.id, value: item?.id, label: item?.district_name }));
    const { websocket } = useContext(WebSocketContext);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    const [showModalNew, setShowModalNew] = useState({
        open: false,
        title: "",
        subtitle: "",
        modalType: "",
        button: "",
        Data: {}
    });

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    const CommonSortingFct = (url) => {
        if (sortingfor === url) {
            Dispatch(getTableSorting("-" + url))
        } else {
            Dispatch(getTableSorting(url))
        };
    };

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // select user type
    const SelectFranchiseUserTypeFnct = (e) => {
        setParentChildFilter(e.target.value);
        setCurrentPage(1);
    };

    // update state wise city 
    const optionCityMemo = useMemo(() => {
        return optionsCity?.filter((elm) => {
            if(selectState === "") {
                return elm;
            } else {
                if(elm?.state_id === selectState?.value) {
                    return elm;
                } else {
                    elm
                };
            }
        })
    }, [ selectState ]);

    // get all state
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "state_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get all city list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "district_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get franchise_type_list all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[1]?.Edit || PermissionsAccess?.AdminTools[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "franchise_type_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[1]?.Edit || PermissionsAccess?.AdminTools[1]?.View)) {
            let ParamUpdate;

            if(ParentChildFilter === "SubDealer") {
                ParamUpdate = { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim(),
                    "state_id" : selectState?.value,
                    "city_id" : selectCity?.value,
                    "order_by": sortingfor,
                    "is_child" : true
                }
            } else if(ParentChildFilter === "Dealer") {
                ParamUpdate = { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim(),
                    "state_id" : selectState?.value,
                    "city_id" : selectCity?.value,
                    "order_by": sortingfor,
                    "is_parent" : true
                }
            } else {
                ParamUpdate = { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "state_id" : selectState?.value,
                    "city_id" : selectCity?.value,
                    "order_by": sortingfor,
                    "search" : userSearch.trim(),
                }
            };

            let param = { 
                "transmit": "broadcast", 
                "url": "franchise_list",
                "request" : ParamUpdate,
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit, ParentChildFilter, selectState, selectCity, sortingfor ]);

    // get pin code
    const GetPinCode = (pin_code_id) => {
        return FranchiseUserTypeList?.pin?.filter((elm) => elm?.id === pin_code_id)[0]?.pin_code;
    };

    // get state name
    const GetStateName = (state_id) => {
        return FranchiseUserTypeList?.state?.filter((elm) => elm?.id === state_id)[0]?.state_name;
    };

    // get city name
    const GetCityName = (district_id) => {
        return FranchiseUserTypeList?.district?.filter((elm) => elm?.id === district_id)[0]?.district_name;
    };

    // get region name
    const GetRegionName = (region_id) => {
        return region_id === null ? "-" : FranchiseUserTypeList?.region?.filter((elm) => elm?.id === region_id)[0]?.region_name;
    };

    // get Regional manager
    const GetRegionalManager = (region_manager_id) => {
        return region_manager_id === null ? "-" : FranchiseUserTypeList?.user?.filter((elm) => elm?.id === region_manager_id)[0]?.full_name;
    };

    // get Assistant Sales manager
    const GetAssistantSalesManager = (asm_id) => {
        return asm_id === null ? "-" : FranchiseUserTypeList?.asm_list?.filter((elm) => elm?.id === asm_id)[0]?.full_name;
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // create applications
    const cmnFnct = () => {
        Navigate(CREATE_FRANCHISE_USER_REPORT_URL);
    };

    // select state
    const StateSelect = (value) => {
        if(value !== null) {
            setSelectState(value);
            setSelectCity("");
        } else {
            setSelectState("");
            setSelectCity("");
        };
    };

    // select city
    const CitySelect = (value) => {
        if(value !== null) {
            setSelectCity(value);
        } else {
            setSelectCity("");
        };
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // row color change active inactive & order count wise
    const StatusWiseBackColor = (data_) => {
        if(!data_?.is_active) {
            return "Order-NoActive-Row";
        } else if(data_?.order_count === 0 && data_?.is_active) {
            return "Order-NotBilled-Row";
        } else {

        };
    };

    // show last three orders
    const FranchiseLastThreeOrders = (orders) => {
        setShowModalNew({
            ...showModalNew,
            title: "Franchise Bikes Orders ( Latest 3 )",
            subtitle: "Franchise Orders Tables",
            modalType: "form",
            open: !showModalNew?.open,
            button: "",
            Data: orders?.id,
        });
    };

    // get franchise type name
    const GetFranchiseTypeName = (franchise_type_id) => {
        const CurrentFranchiseType = FranchiseUserTypeList?.franchise_type?.filter((elm) => elm?.id === franchise_type_id)[0];
        return CurrentFranchiseType?.franchise_type_name;
    };

    return(<section className="Main_section" id="FranchiseUserReport">
        <DataTableFilter 
            searchType={"Name, Franchise Code"}
            filterType={"Franchise User Report"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools[1]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Franchise",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
            ParentChildFilter={ParentChildFilter}
            SelectFranchiseUserTypeFnct={SelectFranchiseUserTypeFnct}
            FilterOpen={FilterOpen}
            setFilterOpen={setFilterOpen}
        />
        <Collapse in={FilterOpen}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group className="mb-3">
                            {/* <Form.Label>Products</Form.Label> */}
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    value={selectState}
                                    onChange={(e) => StateSelect(e)}
                                    isClearable
                                    options={optionsState}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select State"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3">
                            {/* <Form.Label>Products</Form.Label> */}
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    value={selectCity}
                                    onChange={(e) => CitySelect(e)}
                                    isClearable
                                    options={optionCityMemo}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select City"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </Collapse>
        <div className="tableView gstreporttableadded">
            <Scrollbars 
                style={{ height: FilterOpen === true ? parseInt(FranchiseUserTypeList?.pagination?.total_records) === FranchiseUserTypeList?.list?.length ? "calc(100vh - 205px)" : FranchiseUserTypeList?.pagination?.total_records > 10 ? "calc(100vh - 240px)" : "calc(100vh - 205px)" : parseInt(FranchiseUserTypeList?.pagination?.total_records) === FranchiseUserTypeList?.list?.length ? "calc(100vh - 155px)" : FranchiseUserTypeList?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 155px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table responsive bordered hover >
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNO</th>
                            <th className={sortingfor === "customer_name" ? "active" : sortingfor === "-customer_name" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_name")}>Name</th>
                            <th>Email</th>
                            <th className={sortingfor === "franchise_code" ? "active" : sortingfor === "-franchise_code" ? "inactive" : ""} onClick={() => CommonSortingFct("franchise_code")}>Franchise Code</th>
                            <th>ERP ID</th>
                            <th>ERP Address ID</th>
                            <th className={sortingfor === "district_id" ? "active" : sortingfor === "-district_id" ? "inactive" : ""} onClick={() => CommonSortingFct("district_id")}>City</th>
                            <th className={sortingfor === "state_id" ? "active" : sortingfor === "-state_id" ? "inactive" : ""} onClick={() => CommonSortingFct("state_id")}>State</th>
                            <th>PIN Code</th>
                            <th className={sortingfor === "region_id" ? "active" : sortingfor === "-region_id" ? "inactive" : ""} onClick={() => CommonSortingFct("region_id")}>Region</th>
                            <th>Address</th>
                            <th>Type</th>
                            <th>Location</th>
                            <th>Order Count</th>
                            <th>Status</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools[1]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {FranchiseUserTypeList?.list?.map((elm, index) => {
                            return(<React.Fragment key={index}>
                            <tr id={StatusWiseBackColor(elm)}>
                                <td style={{ width: '10px' }}>
                                    <button 
                                        className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                        onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={activeTableDetails === elm?.id ? true : false}
                                    >
                                        <Image src={MoreIcon} alt="more icon" />
                                    </button>
                                </td>
                                <td>
                                    {GetCurrentTableSRNo(index, FranchiseUserTypeList?.pagination?.current_page, FranchiseUserTypeList?.pagination?.record_limit)}
                                </td>
                                <td>{elm?.customer_name}</td>
                                <td>{elm?.email}</td>
                                <td>{elm?.franchise_code?.toString().includes("FC") ? elm?.franchise_code?.replace("FC", "") : elm?.franchise_code}</td>
                                <td>{(elm?.erp_id === null || elm?.erp_id === "") ? "-" : elm?.erp_id}</td>
                                <td>{(elm?.erp_address_id === null || elm?.erp_address_id === "") ? "-" : elm?.erp_address_id}</td>
                                <td>{GetCityName(elm?.district_id)}</td>
                                <td>{GetStateName(elm?.state_id)}</td>
                                <td>{GetPinCode(elm?.pin_code_id)}</td>
                                <td>{GetRegionName(elm?.region_id)}</td>
                                <td>{elm?.address}</td>
                                <td>{elm?.parent_id === null ? "Dealer" : "Sub Dealer"}</td>
                                <td>
                                    {(elm?.location_url !== null && elm?.location_url !== "") ? (<a href={elm?.location_url} target="__blank"><i className="bi bi-geo-alt"></i> Location</a>) : "-"}
                                </td>
                                <td>
                                    {elm?.order_count === 0 ? elm?.order_count : (<b className="tableBolanchor" onClick={() => FranchiseLastThreeOrders(elm)}>{elm?.order_count}</b>)}
                                </td>
                                <td>
                                    {(elm?.is_active && elm?.order_count === 0) ? (<>
                                        {elm?.order_count === 0 && (<Badge className="not_billed_orange" bg="danger">Not Billed</Badge>)}
                                    </>) : (elm?.is_active && elm?.order_count > 0) ? (<>
                                        {elm?.is_active ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">Inactive</Badge>)}
                                    </>) : (<>
                                        {elm?.is_active ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">Inactive</Badge>)}
                                    </>)}
                                </td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools[1]?.Edit) && (<td>
                                    <Link className="btn edittable" to={`/edit-franchise-user-report/${elm?.id}`}>
                                        <i className="bi bi-pencil-square"></i>
                                    </Link>
                                </td>)}
                            </tr>
                            <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                <tr className="tablecollapsewraps" id={"tablecollpase"+elm?.id}>
                                    <td colSpan="8" >
                                        <Table bordered hover className="table">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "25%" }}>Franchise Type</th>
                                                    <td>
                                                        {GetFranchiseTypeName(elm?.franchise_type_id)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "25%" }}>Is Flexible Pricing</th>
                                                    <td>
                                                        {elm?.is_flexible_pricing ? (<Badge bg="success">Yes</Badge>) : (<Badge bg="danger">No</Badge>)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "25%" }}>Regional Manager</th>
                                                    <td>{GetRegionalManager(elm?.region_manager_id)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "25%" }}>Assistant Sales Manager</th>
                                                    <td>{GetAssistantSalesManager(elm?.asm_id)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "25%" }}>Primary Contact Number</th>
                                                    <td>{elm?.mobile}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Secondary Contact Number</th>
                                                    <td>{elm?.secondary_contact === null ? "-" : elm?.secondary_contact}</td>
                                                </tr>
                                                {elm?.ifsc_code &&(
                                                    <tr>
                                                        <th style={{ width: "25%" }}>IFSC Code</th>
                                                        <td>{(elm?.ifsc_code === null || elm?.ifsc_code === "") ? "-" : elm?.ifsc_code}</td>
                                                    </tr>
                                                )}
                                                {elm?.gst_no &&(
                                                    <tr>
                                                        <th style={{ width: "25%" }}>GSTIN</th>
                                                        <td>{elm?.gst_no}</td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <th style={{ width: "25%" }}>Created At</th>
                                                    <td>{CommonDateTime(elm.created_at, "LL", "show")}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            </Collapse>
                        </React.Fragment>)
                        })}
                        {FranchiseUserTypeList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="17">Franchise User Report list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {FranchiseUserTypeList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={FranchiseUserTypeList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>

        {showModalNew?.open && (<CommonewModel 
            setShowModalNew={setShowModalNew} 
            showModalNew={showModalNew} 
        />)}
    </section>)
}

export default FranchiseUserReport;