/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import "react-datepicker/dist/react-datepicker.css";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import Select from 'react-select'
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import { SearchPincodeList, StartCommonLoader, ErrorMessageFromRedux, StartMainCommonLoader } from "../../../redux/actions/adminActions";
import CheckValid from "../../Common/CheckValid";
import { parsePhoneNumber, getRegionCodeForCountryCode } from "awesome-phonenumber";
import CommonewModel from "../../Common/CommonewModel";
import AmountNumberFormat from "../../Common/AmountNumberFormat";

const StocktransferTab = (props) => {
    const { RouteName } = props;
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, selfInfo, ClearFormSet, subCategorylistall, AllDispacchList, categorylistall, productlistall, ErrorMessageFromReddux, searchPincodeList, commonLoader } = useSelector((state) => state.adminReducers);
    const [ selectProduct, setselectProduct ] = useState("");
    const [ selectSubCategory, setselectSubCategory ] = useState("");
    const [ selectCategory, setselectCategory ] = useState("");
    const [ selectItemName, setselectItemName ] = useState("");
    const [ AddQuantity, setAddQuantity ] = useState(1);
    const optionsProducts = productlistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.product_name}));
    const [ optionsCategory, setoptionsCategory ] = useState(() => categorylistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.category_name })));
    const [ optionsDispatch, setoptionsDispatch ] = useState([]);
    const [ optionsToDispatch, setoptionsToDispatch ] = useState([]);
    const [ selectFromWarehouse, setselectFromWarehouse ] = useState("");
    const [ selectToWarehous, setselectToWarehous ] = useState("");
    const [ optionsSubCategory, setoptionsSubCategory ] = useState([]);
    const [ optionsItemName, setoptionsItemName ] = useState([]);
    let GetCurrentProduct = productlistall?.filter((elm) => elm?.id === selectProduct?.value)[0];

    const [ Remarks, setRemarks ] = useState(""); 
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        description: "",
        Data: {}
    });
    const [ PreviewOrder, setPreviewOrder ] = useState(false);
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];
    const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/; // eslint-disable-line
    const pinRegex = /^\d{6}$/; // eslint-disable-line
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // error state
    const [ errorAddress, seterrorAddress ] = useState("");

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    useEffect(() => {
        if(AllDispacchList?.length > 0) {
            let DispatchList = AllDispacchList?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.dispatch_name }));
            setoptionsDispatch(DispatchList);
            setoptionsToDispatch(DispatchList);
        };
    }, [ AllDispacchList ]);

    // get category all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[1]?.Edit || PermissionsAccess?.OrderManagement[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "category_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[1]?.Edit || PermissionsAccess?.OrderManagement[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "subcategory_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all product list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[1]?.Edit || PermissionsAccess?.OrderManagement[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "product_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all franchise list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[1]?.Edit || PermissionsAccess?.OrderManagement[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "franchise_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

     // get all dispatch list
     useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[1]?.Edit || PermissionsAccess?.OrderManagement[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "dispatch_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);
    

    // From WareHouse Select
    const FromWareHouseSelect = (value) => {
        if(value !== null) {
            setselectFromWarehouse(value);
            setoptionsToDispatch(() => optionsToDispatch?.filter((elm) => elm?.id !== value?.value)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.dispatch_name })));
        } else {
            setselectFromWarehouse("");
            setselectToWarehous("");
            PurchaseOrderMainClear();
        }
    };

    // To WareHouse Select
    const ToWareHouseSelect = (value) => {
        if(value !== null) {
            setselectToWarehous(value);
        } else {
            setselectToWarehous("");
            PurchaseOrderMainClear();
        };
    };

    const [ FilterDisable, setFilterDisable ] = useState(false);
    // Product select
    const ProductSelect = (value) => {
        if(value !== null) {
            setselectProduct(value);
            const ProductCurrent = productlistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.id === value?.value)[0];
            const GetCurrentSubCategory = subCategorylistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.id === ProductCurrent?.sub_category_id)[0];
            const CategoryCurrent = categorylistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.id === GetCurrentSubCategory?.category_id);
            const GetCurrentNewSubCategory = subCategorylistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.id === ProductCurrent?.sub_category_id);
            const ProductNewCurrent = productlistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.id === value?.value);
            setoptionsCategory(CategoryCurrent?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name })))
            setoptionsSubCategory(GetCurrentNewSubCategory?.map((item, index)=>({key:item.id, value:item?.id , label:item?.sub_category_name })));
            setoptionsItemName(ProductNewCurrent?.map((item, index)=>({key:item.id, value:item?.id , label:item?.product_name })));
            setselectCategory(CategoryCurrent?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name }))[0]);
            setselectSubCategory(GetCurrentNewSubCategory?.map((item, index)=>({key:item.id, value:item?.id , label:item?.sub_category_name }))[0]);
            setselectItemName(ProductNewCurrent?.map((item, index)=>({key:item.id, value:item?.id , label:item?.product_name }))[0]);
            setFilterDisable(true);
        } else {
            setselectProduct("");
            setFilterDisable(false);
            setselectItemName(null);
            setselectSubCategory(null);
            setselectCategory(null);
            setoptionsSubCategory([]);
            setoptionsItemName([]);
            setoptionsCategory(categorylistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name })))
        }
    };
    
    // category select
    const CategorySelect = (value) => {
        setselectSubCategory(null);
        setselectItemName(null);
        if(value !== null) {
            setselectCategory(value);
            const CategoryCurrent = categorylistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.id === value?.value)[0];
            const SubCategoryCurrent = subCategorylistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.category_id === CategoryCurrent?.id);
            const ProductCurrent = productlistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.sub_category_id === SubCategoryCurrent?.id);
            setoptionsSubCategory(SubCategoryCurrent?.map((item, index)=>({key:item.id, value:item?.id , label:item?.sub_category_name })));
            setoptionsItemName(ProductCurrent?.map((item, index)=>({key:item.id, value:item?.id , label:item?.product_name})));
        } else {
            setselectCategory("");
            setoptionsSubCategory([]);
            setoptionsItemName([]);
            setoptionsItemName([]);
        }
    };

    // sub category select
    const SubCategorySelect = (value) => {
        setselectItemName(null);
        if(value !== null) {
            setselectSubCategory(value);
            const SubCategoryCurrent = subCategorylistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.id === value?.value)[0];
            const ProductCurrent = productlistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => elm?.sub_category_id === SubCategoryCurrent?.id);
            setoptionsItemName(ProductCurrent?.map((item, index)=>({key:item.id, value:item?.id , label:item?.product_name})));
        } else {
            setselectSubCategory("");
        }
    };

    const ItemSelect = (value) => {
        if(value !== null) {
            setselectItemName(value);
        } else {
            setselectItemName("");
        }
    };

    // search pincode
    const onEnterPincodeSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            SearchPincode();
        };
    };

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
          display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    const [ ProductListOrder, setProductListOrder ] = useState([]);
    var ProductMergAarray = [...ProductListOrder];

    function generateRandomId() {
        // Generate a random number
        const randomNumber = Math.floor(Math.random() * 10000);
        
        // Get the current timestamp
        const timestamp = new Date().getTime();
        
        // Concatenate the random number and timestamp to create the ID
        const randomId = `${timestamp}-${randomNumber}`;
        
        return randomId;
    };

    // add purchase order
    const PurchaseOrderAdd = () => {
        if(selectProduct !== "" && selectSubCategory !== "" && selectCategory !== "" && AddQuantity > 0) {
            let Obj = {
                id: generateRandomId(),
                product: selectProduct,
                sub_category: selectSubCategory,
                category: selectCategory,
                quantity: parseInt(AddQuantity),
            };
            ProductMergAarray?.push(Obj);
        
            let mergedArray = mergeAndUpdate(ProductMergAarray);
            // unique array convert
            setProductListOrder(mergedArray);
            PurchaseOrderClear();
        } else {

        };
    };

    // merge array and add quantity
    function mergeAndUpdate(arr) {
        let mergedArray = [];
        arr.forEach(item => {
            // Check if the item already exists in the merged array
            const existingItemIndex = mergedArray.findIndex(
                mergedItem => mergedItem?.product?.value === item?.product?.value && mergedItem.is_demo === item.is_demo
            );
    
            if (existingItemIndex !== -1) {
                // If the item exists, update its quantity
                mergedArray[existingItemIndex].quantity += item.quantity;
            } else {
                // If the item doesn't exist, add it to the merged array
                mergedArray.push({...item});
            }
        });
    
        return mergedArray;
    };


    // Function to handle adding quantity
    const addMainQuantity = (id) => {
        const AddQauntArray = ProductMergAarray?.map((elm) => {
            if(elm?.id === id) {
                return {
                    ...elm,
                    quantity: parseInt(elm?.quantity) + 1,
                }
            } else {
                return elm;
            }
        });
        setProductListOrder(AddQauntArray);
    };

    // Function to handle removing quantity
    const removeQuantity = (id, value) => {
        const RemovedQunty = ProductMergAarray?.map((elm) => {
            if(value === 1) {
                if(elm?.id !== id) {
                    return elm;
                }
            } else {
                if(elm?.id === id) {
                    return {
                        ...elm,
                        quantity: parseInt(elm?.quantity) - 1,
                    }
                } else {
                    return elm;
                }
            }
        })?.filter((elm) => elm !== undefined);
        setProductListOrder(RemovedQunty);
    };

    // remove order
    const DeleteOrder = (id) => {
        const RemoveOrder = [];
        ProductMergAarray?.map((elm) => {
            if(elm?.id !== id) {
                return RemoveOrder.push(elm);
            }
        });
        setProductListOrder(RemoveOrder);
    };

    const ConfirmOrder = () => {
        const ProductListOrderStateArray = [];
        ProductListOrder?.filter((elm) => {
            return  ProductListOrderStateArray?.push({
                product_id: elm?.product?.value,
                product_qty: elm?.quantity,
                is_demo: false,
            });
        });
        if(Remarks !== "") {
            Dispatch(StartMainCommonLoader({
                url: "order_add",
                loader: true
            }));
            let param = { 
                "transmit": "broadcast", 
                "url": "order_add",
                "request" : { 
                    "order_type" : "WO", 
                    "wh_from": selectFromWarehouse?.id, 
                    "wh_to": selectToWarehous?.id, 
                    "warehouse_remark": Remarks, 
                    "items": ProductListOrderStateArray
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        } else {
            CheckValid(Remarks, {type: 'Remarks', error: seterrorAddress})
        }
    };

    const onChange = (e) => {
        let value = e.target.value;
    
        // Remove leading zeros
        value = value.replace(/^0+/g, "");
    
        // Remove non-numeric characters
        value = value.replace(/\D/g, "");
    
        // Ensure the value is more than 1 (not negative number)
        if (value === "" || parseInt(value) < 0) {
            value = ""; // Set default value to 1
        }
    
        // Update the state with the modified value
        setAddQuantity(parseInt(value));
    }

    const OrderPurchaseFunct = () => {
        if(Remarks !== "") {
            setShowModalNew({
                ...showModalNew,
                open: open,
                title: "Confirmation",
                subtitle: "Order-Purchase",
                modalType: "Order-Purchase",
                description: "Are you sure to proceed for Order?",
                modalType: "not form",
                button: "Yes",
                Data: {}
            });
        } else {
            CheckValid(Remarks, {type: 'Remarks', error: seterrorAddress})
        };
    };

    // clear purchase order
    const PurchaseOrderClear = () => {
        setselectProduct("");
        setselectSubCategory("");
        setselectCategory("");
        setAddQuantity(1);
        setFilterDisable(false);
        setoptionsSubCategory([]);
        setoptionsItemName([]);
        setselectItemName("");
        setRemarks("");
        setoptionsCategory(() => categorylistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name })));
    };

    // all clears data
    const PurchaseOrderMainClear = () => {
        setselectProduct("");
        setselectSubCategory("");
        setselectCategory("");
        PurchaseOrderClear();
        setProductListOrder([]);
        setFilterDisable(false);
        setoptionsSubCategory([]);
        setoptionsItemName([]);
        setselectItemName("");
        setRemarks("");
        setoptionsCategory(() => categorylistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name })));
    };

    function getGstAmt(price, gstRate) {
        return (parseFloat(price)  - ( parseFloat(price) / (1 + (parseFloat(gstRate) / 100))));
    };

    // gst get
    const GetGSTAmount = () => {
        let total = 0;
        let total_gst = 0;
        let price = 0;
        let sub_total = 0;
        ProductListOrder?.filter((elm) => {
            const ProductGet = productlistall?.filter((elms) => elms?.id === elm?.product?.value)[0]
            price = ProductGet?.mrp;
            let gst_rate = ProductGet.igst_rate
            
            sub_total = parseFloat(parseFloat(price) * parseInt(elm?.quantity) * 100);
            total = total + sub_total;
            // total_gst = parseInt(total_gst + (parseInt((parseInt(gst_rate) / 100) * sub_total)));
            total_gst = total_gst + (getGstAmt(price, gst_rate) * parseInt(elm?.quantity))

        });
        
        return {
            total: total/100,
            total_gst: total_gst,
        };
    };

    const GetCurrentOrderRate = (current, product) => {
        let price = 0;
        let sub_total = 0;
        price = parseFloat(product?.mrp === null ? 0 : product?.mrp);
       
        sub_total = parseFloat(parseFloat(price) * parseFloat(current?.quantity) * 100);
        
        return {
            price: price,
            sub_total: sub_total/100,
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            PurchaseOrderMainClear();
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
            setShowModalNew({
                ...showModalNew,
                open: false,
                Data: {}
            });
            setPreviewOrder(false);
            Dispatch(SearchPincodeList({}));
            setRemarks("");
            setselectToWarehous("");
            setselectFromWarehouse("");
        };
    }, [ ClearFormSet ]);

    const DisableAddButton = selectProduct !== "" && selectSubCategory !== "" && selectCategory !== "" && AddQuantity > 0;

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });
    
    return(<section className="Main_section">
        {!PreviewOrder && (<div className="filtr_Form Purchase_Order_Mendetory" style={{ borderBottom: "1px solid #eee" }}>
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-3">
                            <Form.Group className="mb-3">
                                <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                    <Select 
                                        onChange={(e) => FromWareHouseSelect(e)} 
                                        value={selectFromWarehouse}
                                        isClearable 
                                        options={optionsDispatch}
                                        className="godown-select-container" 
                                        classNamePrefix="godown-select"
                                        placeholder="From Warehouse"
                                        styles={customStyles}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group className="mb-3">
                                <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                    <Select 
                                        onChange={(e) => ToWareHouseSelect(e)} 
                                        value={selectToWarehous}
                                        isClearable 
                                        options={optionsToDispatch}
                                        className="godown-select-container" 
                                        classNamePrefix="godown-select"
                                        placeholder="To Warehouse"
                                        styles={customStyles}
                                        isDisabled={selectFromWarehouse === ""}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        {(selectFromWarehouse !== "" && selectToWarehous !== "") && (<React.Fragment>
                            <div className="col-md-3">
                                <Form.Group className="mb-3">
                                    <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                        <Select 
                                            onChange={(e) => ProductSelect(e)} 
                                            value={selectProduct}
                                            isClearable 
                                            options={optionsProducts}
                                            className="godown-select-container" 
                                            classNamePrefix="godown-select"
                                            placeholder="Select Products"
                                            styles={customStyles}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3" >
                                    <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                        <Select 
                                            onChange={(e) => CategorySelect(e)} 
                                            value={selectCategory}
                                            // defaultValue={DefaultCategory} 
                                            isClearable 
                                            options={optionsCategory}
                                            className="godown-select-container" 
                                            classNamePrefix="godown-select"
                                            placeholder="Select Category"
                                            styles={customStyles}
                                            isDisabled={FilterDisable}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3" >
                                    <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                        <Select 
                                            onChange={(e) => SubCategorySelect(e)} 
                                            value={selectSubCategory}
                                            // defaultValue={DefaultSubCategory} 
                                            // isClearable 
                                            options={optionsSubCategory}
                                            className="godown-select-container" 
                                            classNamePrefix="godown-select"
                                            placeholder="Select Sub Category"
                                            styles={customStyles}
                                            isDisabled={FilterDisable}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3" >
                                    <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                        <Select 
                                            onChange={(e) => ItemSelect(e)} 
                                            value={selectItemName}
                                            // defaultValue={DefaultSubCategory} 
                                            // isClearable 
                                            options={optionsItemName}
                                            className="godown-select-container" 
                                            classNamePrefix="godown-select"
                                            placeholder="Select Item Name"
                                            styles={customStyles}
                                            isDisabled={FilterDisable}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <Form.Group className="mb-3" >
                                    <Form.Control 
                                        type="number"
                                        value={AddQuantity} 
                                        className="inputBoxs"
                                        onChange={onChange}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e)
                                            exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                        }}
                                        placeholder="Enter Quantity"
                                        maxLength={3}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-3">
                                <button className="btn exportbtn" disabled={!DisableAddButton} id="PurchaseOrderbtn" onClick={() => PurchaseOrderAdd()}>
                                    Add
                                </button>
                                <button className="btn exportbtn" style={{ marginLeft: "10px" }} id="PurchaseOrderClearbtn" onClick={() => PurchaseOrderClear()}>
                                    <i className="bi bi-x-circle-fill"></i>&nbsp;Clear
                                </button>
                            </div>
                        </React.Fragment>)}
                    </div>
                </div>
            </div>)}
            <div className="tableView">
                <Scrollbars 
                    style={{ 
                        height: PreviewOrder ? "calc(100vh - 146px)" : GetCurrentProduct === undefined ? "calc(100vh - 270px)" : "calc(100vh - 225px)"
                    }} 
                    renderView={props => <div {...props} className="view"/>}
                    renderTrackHorizontal={renderTrack}
                    className="ScrollbarsSidebar"
                >
                    {PreviewOrder && (<div className="FranchiseWallet" >
                        <div className="Franchise_name">
                            <label>From Warehouse:</label>
                            {selectFromWarehouse?.label}
                            <label>To Warehouse:</label>
                            {selectToWarehous?.label}
                        </div>
                        <button className="btn btn-link backbtn" onClick={() => {setPreviewOrder(false), setRemarks("")}} style={{ display: "flex" }}>
                            <i className="bi bi-arrow-left-short"></i> Back
                        </button>
                    </div>)}

                    {ProductListOrder?.length > 0 && (<>
                        <div className="tableView">
                            <Table responsive bordered  >
                                <thead>
                                    <tr>
                                        <th>SNO</th>
                                        <th>Product Code</th>
                                        <th>Product Name</th>
                                        <th>Rate</th>
                                        <th>Quantity</th>
                                        <th>Amount</th>
                                        {!PreviewOrder && (<th>Action</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {ProductListOrder?.map((elm, index) => {
                                        return(<tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{elm?.product?.product_code}</td>
                                            <td>{elm?.product?.product_name}</td>
                                            <td>₹ {AmountNumberFormat(GetCurrentOrderRate(elm, elm?.product)?.price)}</td>
                                            <td>
                                                {PreviewOrder ? elm?.quantity : (<div className="QauntityManage">
                                                    <button className="btn remove" onClick={() => removeQuantity(elm?.id, elm?.quantity)}>
                                                        <i className="bi bi-dash-circle-fill"></i>
                                                    </button>
                                                    <h2>{elm?.quantity}</h2>
                                                    <button className="btn add" onClick={() => addMainQuantity(elm?.id)}>
                                                        <i className="bi bi-plus-circle-fill"></i>
                                                    </button>
                                                </div>)}
                                            </td>
                                            <td>₹ {AmountNumberFormat(GetCurrentOrderRate(elm, elm?.product)?.sub_total)}</td>
                                            {!PreviewOrder && (<td>
                                                <Button className="btn edittable" onClick={() => DeleteOrder(elm?.id)} style={{ marginLeft: "7px" }}>
                                                    <i className="bi bi-trash" style={{ color: "red" }}></i> 
                                                </Button>
                                            </td>)}
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        {!PreviewOrder && (<div className="btnGroupswraps" id="btnGroupswraps">
                            <Button className="Addbtn" onClick={() => setPreviewOrder(!PreviewOrder)}>Preview</Button>
                        </div>)}
                        {PreviewOrder && (<>
                            <ul className="GSTAmounttotal">
                                <li className="totaltd">
                                    <label>GST: &nbsp;</label>
                                    ₹ {AmountNumberFormat(GetGSTAmount()?.total_gst)}
                                </li>
                                <li className="totaltd">
                                    <label>Net Amount: &nbsp;</label>
                                    ₹ {AmountNumberFormat(GetGSTAmount()?.total)}
                                </li>
                            </ul>
                            <div className="tabsWrappers" style={{ maxWidth: "50%" }}>
                                <div className="formloginset">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Form.Group className="mb-3 ">
                                                <Form.Label>Remarks <span className="mendatory_star">*</span></Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    as="textarea" rows={3}
                                                    value={Remarks} 
                                                    onChange={(e) => setRemarks(e.target.value)}
                                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Remarks', error: seterrorAddress})}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    placeholder="Enter Remarks(Max length 200 characters)" 
                                                    maxLength={200}
                                                />
                                                {errorAddress !== "" &&<span className="error_medotory">{errorAddress}</span>}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-3" style={{ textAlign: "right" }}>
                                            {selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[1]?.Edit) ? (<Button className="formbtn me-2" onClick={() => OrderPurchaseFunct()} style={{ width: "auto" }} variant="primary" >
                                                Submit
                                            </Button>) : <span style={{ color: "red" }}>You don't have permission to create an order.</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)}
                    </>)}
                </Scrollbars>
            </div>

            {showModalNew?.open && (<CommonewModel ConfirmOrder={ConfirmOrder} setShowModalNew={setShowModalNew} showModalNew={showModalNew}/>)}
    </section>)
}

export default StocktransferTab;