 // eslint-disable-next-line
import React from 'react'
import { Image, Table } from 'react-bootstrap'
import CommonDateTime from './CommonDateTime';
import AmountNumberFormat from './AmountNumberFormat';
import Logo from "../../assets/img/logo.png";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PurchaseOrderReport(props) {
    const { selfInfo } = useSelector((state) => state.adminReducers);
    const { OrderReportDetails } = props;
    const PendingForStores = useLocation()?.pathname?.split("/")[1];
  
  return (
    <React.Fragment>
        {PendingForStores === "fba-orders-details" ? (<React.Fragment>
            <Table >
                <tbody>
                    <tr>
                        <td style={{ padding: "0px" }}>
                            <Image src={Logo} style={{ width: "120px" }} alt="logo" />
                        </td>
                        <td style={{ padding: "0px", textAlign: "center" }}>
                            <Table >
                                <tbody>
                                    <tr>
                                        <td style={{ padding: "0px 0px 5px 0px", borderBottom: "0px", fontSize: "14px", fontWeight: "600" }} colSpan={4}>Bill To - {OrderReportDetails?.order?.shipping_name}.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 0px 5px 0px", borderBottom: "0px", fontSize: "14px" }} colSpan={4}>Address: {OrderReportDetails?.order?.bill_city} - {OrderReportDetails?.order?.bill_pincode}, {OrderReportDetails?.order?.bill_state}, India.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 0px 5px 0px", borderBottom: "0px", fontSize: "14px", fontWeight: "600" }} colSpan={4}>Ship To - {OrderReportDetails?.order?.shipping_name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 0px 5px 0px", borderBottom: "0px", fontSize: "14px" }} colSpan={4}>Address: {OrderReportDetails?.order?.shipping_city} - {OrderReportDetails?.order?.shipping_pincode}, {OrderReportDetails?.order?.shipping_state}, India.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px", borderBottom: "0px", fontSize: "14px" }}>Phone : {OrderReportDetails?.order?.bill_phone}</td>
                                        <td style={{ padding: "0px", borderBottom: "0px", fontSize: "14px" }}>EMail ID : {OrderReportDetails?.order?.bill_email}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                    <tr>
                        <th style={{ padding: "17px 0px", fontSize: "14px", textAlign: "center" }} colSpan={2}>
                            {/* PURCHASE ORDER */}
                            SALES ORDER
                        </th>
                    </tr>
                    <tr>
                        <td style={{ padding: "0px", textAlign: "left" }} colSpan={2}>
                            <Table >
                                <tbody>
                                    {/* <tr>
                                        <td style={{ padding: "7px 0px", borderBottom: "0px", fontSize: "14px" }}>Franchise ID : {OrderReportDetails?.order?.billing?.franchise_code?.replace("FC","")}</td>
                                    </tr> */}
                                    <tr>
                                        <td style={{ padding: "7px 0px", borderBottom: "0px", fontSize: "14px" }}>Order No : {OrderReportDetails?.order?.display_order_code}</td>
                                        <td style={{ padding: "7px 0px", borderBottom: "0px", fontSize: "14px", textAlign: "center" }}>
                                            Order Date : {CommonDateTime(OrderReportDetails?.order?.created_at, "LL", "show")}
                                        </td>
                                        <td style={{ padding: "7px 0px", borderBottom: "0px", fontSize: "14px", textAlign: "center" }}>
                                            Invoice Date : {CommonDateTime(OrderReportDetails?.order?.invoice_at, "LL", "show")}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Table className="printTable table-common" bordered hover>
                <thead>
                    <tr>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Sl. No.</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Product Description	</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>HSN Code</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>UOM</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Rate</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Qty</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Taxable Amount</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>State Gst Percentage</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Central Gst Percentage</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {OrderReportDetails?.order_items_list?.map((elm, index) => {
                        return(<tr key={index}>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{index + 1}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{elm?.item_name}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{elm?.hsn_code}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{elm?.item_sku}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{AmountNumberFormat(elm?.selling_price)}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{elm?.qty}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{parseFloat(elm?.selling_price_without_taxes_and_discount).toFixed(2)}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{elm?.state_gst_percentage}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{elm?.central_gst_percentage}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{AmountNumberFormat(parseInt(elm?.total_price * elm?.qty))}</td>
                        </tr>)
                    })}
                    <tr >
                        <td colSpan={4}></td>
                        <td colSpan={OrderReportDetails?.order?.ntr === "Intra"?8:6}>
                            <span>Status: <b> {OrderReportDetails?.order?.marketplace_status?.replace("_", " ")}</b></span>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </React.Fragment>) : (<React.Fragment>
            <Table >
                <tbody>
                    <tr>
                        <td style={{ padding: "0px" }}>
                            <Image src={Logo} style={{ width: "120px" }} alt="logo" />
                        </td>
                        <td style={{ padding: "0px", textAlign: "center" }}>
                            <Table >
                                <tbody>
                                    <tr>
                                        <td style={{ padding: "0px 0px 5px 0px", borderBottom: "0px", fontSize: "14px", fontWeight: "600" }} colSpan={4}>Bill To - {OrderReportDetails?.order?.customer_name}.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 0px 5px 0px", borderBottom: "0px", fontSize: "14px" }} colSpan={4}>Address: {OrderReportDetails?.order?.billing?.address},{OrderReportDetails?.order?.billing?.address2} {OrderReportDetails?.order?.billing_pin_data?.district?.district_name} - {OrderReportDetails?.order?.billing_pin_data?.pin_code?.pin_code}, {OrderReportDetails?.order?.billing_pin_data?.state?.state_name}, India.</td>
                                    </tr>
                                    <tr>
                                        <th style={{ padding: "0px 0px 5px 0px", borderBottom: "0px", fontSize: "14px" }} colSpan={4}>{OrderReportDetails?.order?.customer_name}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 0px 5px 0px", borderBottom: "0px", fontSize: "14px" }} colSpan={4}>Address: {OrderReportDetails?.order?.address}, {OrderReportDetails?.order?.district?.district_name} - {OrderReportDetails?.order?.pin_code?.pin_code}, {OrderReportDetails?.order?.state?.state_name}, India.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px", borderBottom: "0px", fontSize: "14px" }}>Phone : {OrderReportDetails?.order?.mobile}</td>
                                        <td style={{ padding: "0px", borderBottom: "0px", fontSize: "14px" }}>EMail ID : {OrderReportDetails?.order?.email}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                    <tr>
                        <th style={{ padding: "17px 0px", fontSize: "14px", textAlign: "center" }} colSpan={2}>
                            {/* PURCHASE ORDER */}
                            SALES ORDER
                        </th>
                    </tr>
                    <tr>
                        <td style={{ padding: "0px", textAlign: "left" }} colSpan={2}>
                            <Table >
                                <tbody>
                                    <tr>
                                        <td style={{ padding: "7px 0px", borderBottom: "0px", fontSize: "14px" }}>Franchise ID : {OrderReportDetails?.order?.billing?.franchise_code?.replace("FC","")}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "7px 0px", borderBottom: "0px", fontSize: "14px" }}>Order No : {OrderReportDetails?.order?.order_code}</td>
                                        <td style={{ padding: "7px 0px", borderBottom: "0px", fontSize: "14px", textAlign: "center" }}>
                                            Order Date : {CommonDateTime(OrderReportDetails?.order?.created_at, "LL", "show")}
                                        </td>
                                        <td style={{ padding: "7px 0px", borderBottom: "0px", fontSize: "14px", textAlign: "center" }}>
                                            Invoice Date : {CommonDateTime(OrderReportDetails?.order?.invoice_at, "LL", "show")}
                                            {(PendingForStores === "pending-for-stores" && selfInfo?.user?.user_type ==="super_admin") &&(<a href='/stock-level' className='sotklevelbtn' target='__blank'>Stock Level</a>)}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Table className="printTable table-common" bordered hover>
                <thead>
                    <tr>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Sl. No.</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Product Description	</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>HSN Code</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>UOM</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Rate</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Qty</th>
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Taxable Amount</th>
                        {OrderReportDetails?.order?.ntr === "Intra" ? (
                            <React.Fragment>
                                <th style={{ padding: "0px", borderBottom: "0px", fontSize: "14px", textAlign: "center" }} colSpan={2}>
                                    SGST
                                    <Table bordered hover style={{ margin: "0px", textAlign: "center" }}>
                                        <tr>
                                            <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Rate</th>
                                            <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Amount</th>
                                        </tr>
                                    </Table>
                                </th>
                                <th style={{ padding: "0px", borderBottom: "0px", fontSize: "14px", textAlign: "center" }} colSpan={2}>
                                    CGST
                                    <Table bordered hover style={{ margin: "0px", textAlign: "center" }}>
                                        <tr>
                                            <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Rate</th>
                                            <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Amount</th>
                                        </tr>
                                    </Table>
                                </th>
                            </React.Fragment>
                        ):(
                            <th style={{ padding: "0px", borderBottom: "0px", fontSize: "14px", textAlign: "center" }} colSpan={2}>
                                IGST
                                <Table bordered hover style={{ margin: "0px", textAlign: "center" }}>
                                    <tr>
                                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Rate</th>
                                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Amount</th>
                                    </tr>
                                </Table>
                            </th>
                        )}
                        <th style={{ padding: "7px 7px", borderBottom: "0px", fontSize: "14px" }}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {OrderReportDetails?.order_items_list?.map((elm, index) => {
                        return(<tr key={index}>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{index + 1}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{elm?.product_name}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{elm?.hsn_code}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{elm?.sku_type}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{AmountNumberFormat(elm?.price)}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{elm?.qty}</td>
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{parseFloat(elm?.sub_total - elm?.sub_gst).toFixed(2)}</td>
                            {OrderReportDetails?.order?.ntr === "Intra" ? (
                                <React.Fragment>
                                    <td style={{ padding: "7px 7px", fontSize: "14px" }}>{parseInt(elm?.sgst_rate).toFixed(2)}%</td>
                                    <td style={{ padding: "7px 7px", fontSize: "14px" }}>{elm?.sub_sgst}</td>
                                    <td style={{ padding: "7px 7px", fontSize: "14px" }}>{parseInt(elm?.cgst_rate).toFixed(2)}%</td>
                                    <td style={{ padding: "7px 7px", fontSize: "14px" }}>{elm?.sub_cgst}</td>
                                </React.Fragment>
                            ):(
                                <React.Fragment>
                                    <td style={{ padding: "7px 7px", fontSize: "14px" }}>{parseInt(elm?.gst_rate).toFixed(2)}%</td>
                                    <td style={{ padding: "7px 7px", fontSize: "14px" }}>{elm?.sub_gst}</td>
                                </React.Fragment>
                            )}
                            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{AmountNumberFormat(parseInt(elm?.sub_total))}</td>
                        </tr>)
                    })}
                    <tr >
                        <td colSpan={4}></td>
                        <td colSpan={OrderReportDetails?.order?.ntr === "Intra"?8:6}>
                            <span>Status: <b> {OrderReportDetails?.order?.order_status?.replace("_", " ")}</b></span>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </React.Fragment>)}
    </React.Fragment>
  )
}
