/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Badge, Collapse, Image } from 'react-bootstrap';
import DataTableFilter from "../../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate, Link } from "react-router-dom";
import { CREATE_PRODUCT_URL } from "../../Shared/constant";
import { WebSocketContext } from "../../../App";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../../Common/CommonPagination";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import MoreIcon from '../../../assets/img/more_options.png';
import CommonDateTime from "../../Common/CommonDateTime";

const Productcreation = (props) => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { RouteName } = props;
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { productList, categorylistall, brandlistall, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // get category all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[4]?.Edit || PermissionsAccess?.ProductMasters[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "brand_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get category all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[4]?.Edit || PermissionsAccess?.ProductMasters[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "category_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[4]?.Edit || PermissionsAccess?.ProductMasters[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "box_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[4]?.Edit || PermissionsAccess?.ProductMasters[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "subcategory_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[4]?.Edit || PermissionsAccess?.ProductMasters[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "product_group_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[4]?.Edit || PermissionsAccess?.ProductMasters[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "product_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim()
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // get GetBrand Name
    const GetBrandName = (sub_category) => {
        const SubCategoryListx = productList?.sub_category?.filter((elm) => sub_category === elm?.id)[0];
        const CategoryListX = categorylistall?.filter((elm) => SubCategoryListx?.category_id === elm?.id)[0];
        return brandlistall?.filter((elm) => CategoryListX?.brand_id === elm?.id)[0]?.brand_name;
    };

    //get category name 
    const GetCategoryName = (sub_category) => {
        const SubCategoryListx = productList?.sub_category?.filter((elm) => sub_category === elm?.id)[0];
        return categorylistall?.filter((elm) => SubCategoryListx?.category_id === elm?.id)[0]?.category_name;
    };

    // get sub Category name
    const GetSubCategoryName = (sub_category) => {
        return productList?.sub_category?.filter((elm) => sub_category === elm?.id)[0]?.sub_category_name;
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // create applications
    const cmnFnct = () => {
        Navigate(CREATE_PRODUCT_URL);
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"Product"}
            searchType={"Product Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ProductMasters[4]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Product",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: productList?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 150px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table responsive bordered hover >
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNO</th>
                            <th>Product Name</th>
                            <th>MasterSku</th>
                            <th>Product Code</th>
                            <th>ERP ID</th>
                            <th>SubCategory Name</th>
                            <th>Status</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ProductMasters[4]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {productList?.list?.map((elm, index) => { 
                            return(<React.Fragment key={index}>
                                <tr key={index}>
                                    <td style={{ width: '10px' }}>
                                        <button 
                                            className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                            onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={activeTableDetails === elm?.id ? true : false}
                                        >
                                            <Image src={MoreIcon} alt="more icon" />
                                        </button>
                                    </td>
                                    <td>
                                        {GetCurrentTableSRNo(index, productList?.pagination?.current_page, productList?.pagination?.record_limit)}
                                    </td>
                                    <td>{elm?.product_name}</td>
                                    <td>{elm?.sku}</td>
                                    <td>{elm?.product_code === null ? "-" : elm?.product_code}</td>
                                    <td>{(elm?.erp_id === null || elm?.erp_id === "") ? "-" : elm?.erp_id}</td>
                                    <td>{GetSubCategoryName(elm?.sub_category_id)}</td>
                                    <td>
                                        {elm?.is_active ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                                    </td>
                                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ProductMasters[4]?.Edit) && (<td>
                                        <Link className="btn edittable" to={`/edit-product/${elm?.id}`}>
                                            <i className="bi bi-pencil-square"></i>
                                        </Link>
                                    </td>)}
                                </tr>
                                <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                    <tr className="tablecollapsewraps" id={"tablecollpase"+elm?.id}>
                                        <td colSpan="8" >
                                            <Table bordered hover className="table">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Application Name</th>
                                                        <td>{GetBrandName(elm?.sub_category_id)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Category Name</th>
                                                        <td>{GetCategoryName(elm?.sub_category_id)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Mrp</th>
                                                        <td>{elm?.mrp === null ? "-" : elm?.mrp}</td>
                                    
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>E-Comm. Price</th>
                                                        <td>{elm?.ecom_price === null ? "-" : elm?.ecom_price}</td>
                                    
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Franchise Price</th>
                                                        <td>{elm?.franchise_rate === null ? "-" : elm?.franchise_rate}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Created At</th>
                                                        <td>{CommonDateTime(elm.created_at, "LL", "show")}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                </Collapse>
                            </React.Fragment>)
                        })}
                        {productList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">Product list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {productList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={productList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
    </section>)
}

export default Productcreation;