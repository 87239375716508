/* eslint-disable */
import React, { useState } from "react";
import { Table, Button, Form, Badge } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";

const FranchiseStoreDetails = (props) => {
    const { RouteName } = props;
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const today = new Date();
    // active supplier
    // const AllowCategoryGet = (data_, type, booleancheck) => {
    //     setShowModal({
    //         ...showModal,
    //         open: !showModal?.open,
    //         title: "Are you sure?",
    //         subtitle: "Active Category",
    //         description: `Do you really want to ${booleancheck ? "remove " : ""} ${type.split("_")[1]} to <h5>${data_?.name}</h5>`,
    //         modalType: "not form",
    //         button: "Yes",
    //         Data: data_
    //     });
    // }; 

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
          display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    return(<section className="Main_section">
        <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group  >
                            {/* <Form.Label>From:</Form.Label> */}
                            <div className="datepickField">
                                <DatePicker 
                                    // selected={DateSelect} 
                                    // onChange={(date) => setDateSelect(date)} 
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth 
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    // maxDate={newDate}
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" >
                        <Form.Group >
                            {/* <Form.Label>To:</Form.Label> */}
                            <div className="datepickField">
                                <DatePicker 
                                    // selected={DateSelect} 
                                    // onChange={(date) => setDateSelect(date)} 
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth 
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    // maxDate={newDate}
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group >
                            {/* <Form.Label>Status</Form.Label> */}
                            <select className="form-control">
                                <option value="">Select Status</option>
                                <option value="Sold">Sold</option>
                                <option value="Un-Sold">Un-Sold</option>
                            </select>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </div>
        <DataTableFilter 
            filterType={""}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: "calc(100vh - 100px)" }} 
                renderView={props => <div {...props} className="view"/>}
                renderTrackHorizontal={renderTrack}
                className="ScrollbarsSidebar"
            >
                <Table responsive bordered hover >
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th>Frame Number</th>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Status</th>
                            <th>Added On</th>
                            <th>Sold On</th>
                            <th>Order No</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>FR10011</td>
                            <td>THE RAJA CYCLE STORES</td>
                            <td>005</td>
                            <td>
                                <Badge bg="success">Active</Badge>
                            </td>
                            <td>03 Mar 2023</td>
                            <td>04 Mar 2023</td>
                            <td>1/2324/00079</td>
                        </tr>
                    </tbody>
                </Table>
            </Scrollbars>
        </div>
    </section>)
}

export default FranchiseStoreDetails;