

/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge, Collapse, Image, Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import MoreIcon from '../../../assets/img/more_options.png';
import { useNavigate } from "react-router-dom";
import { TimeSense, getColor, getStringOfErrorValues } from "../../Common/TimeSense";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import Select from 'react-select';
import CommonExport from "../../Common/CommonExport";

const DebitCreditNoteReport = (props) => {
    const navigate = useNavigate();
    const Dispatch = useDispatch();
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("all");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [activeTableDetails, setActiveTableDetails] = useState("");
    const [show, setShow] = useState(null)
    const today = new Date();
    const { device_id, AllDealerFranchiseTypeListAll, ClearFormSet, DebitCreditNoteReportList, selfInfo } = useSelector((state) => state.adminReducers);
    const [FranchiseListOption, setFranchiseListOption] = useState(() => AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.is_active === true && elm?.parent_id === null)?.map((item, index) => ({ ...item, key: item.id, value: item?.id, label: item?.customer_name })))
    const [OrderViewerModal, setOrderViewerModal] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    const [Franchise, setFranchise] = useState("");
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // get all franchise list
    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "franchise_list",
            "request": {
                "status": "",
                "limit": 5000,
                "page_no": 1,
                "search": ""
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            // setEndDate(date)
            setToDate(date);
        }
    };

    // franchise select fucntion
    const FranchiseSelect = (value) => {
        if (value !== null) {
            setFranchise(value);
        } else {
            setFranchise("");
        };
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200) {
            callFilterData()
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        };
    }, [ClearFormSet]);

    useEffect(() => {
        // if (selfInfo?.user?.user_type === 'super_admin') {
            callFilterData();
        // }
    }, [currentPage, userLimit, FromDate, ToDate, Franchise]);

    const callFilterData = () => {
        let param = {
            "transmit": "broadcast",
            "url": "dncn_list",
            "request": {
                "limit": userLimit,
                "page_no": currentPage,
                "from_date": null,
                "to_date": null,
                "franchise_id": Franchise?.value
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    };

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };
    const responseText = (response1) => {
        const response = JSON.parse(response1);
        let text = "";
        if (typeof (response?.errors) == "object") {
            text = text + `</p><b>errors:</b> ${getStringOfErrorValues(response?.errors)}</p>`;
        }
        if (typeof (response?.message) == "object") {
            text = text + `</p><b>message:</b> ${getStringOfErrorValues(response?.message)}</p>`;
        }
        if (typeof (response?.response) == "object") {
            text = text + `</p><b>response:</b> ${getStringOfErrorValues(response?.response)}</p>`;
        }
        if (typeof (response?.message) == "string") {
            text = text + `</p><b>message:</b> ${getStringOfErrorValues(response?.message)}</p>`;
        }
        return text;
    }

    // get frachise data
    const GetFranchiseData = (id) => {
        const franchise = DebitCreditNoteReportList?.franchise?.filter((elm) => elm?.id === id)[0];
        return {
            FranchiseCode: franchise?.franchise_code,
            FranchiseName: franchise?.customer_name,
        };
    };

    //user detail
    const GetUserData = (id) => {
        const user = DebitCreditNoteReportList?.user?.filter((elm) => elm?.id === id)[0];
        return {
            UserName: user?.full_name,
        };
    };

    // total table count
    const TotalTableCount = (list, url) => {
        if (list?.length > 0) {
            if (url === "credit_amt") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.credit_amt);
                }
                return sum;
            } else if (url === "debit_amt") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.debit_amt);
                }
                return sum;
            };
        } else {
            return 0;
        };
    };

    return (<section className="Main_section">
        <div className="row">
            <div className="col-md-3">
            <Form.Group>
                    <div className="datepickField min">
                        <DatePicker
                            selected={FromDate}
                            startDate={FromDate}
                            endDate={ToDate}
                            onChange={(date) => DateStartGet(date)}
                            dateFormat="dd/MM/yyyy"
                            maxDate={today}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Enter From Date"
                            isClearable={FromDate !== "" ? true : false}
                            name="statDate"
                            autoComplete="off"
                        />
                    </div>
                </Form.Group>
            </div>
            <div className="col-md-3" >
                <Form.Group>
                    <div className="datepickField">
                        <DatePicker
                            selected={ToDate}
                            startDate={FromDate}
                            endDate={ToDate}
                            minDate={startDate}
                            maxDate={endDate}
                            onChange={(date) => DateEndGet(date)}
                            dateFormat="dd/MM/yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Enter To Date"
                            isClearable={ToDate !== "" ? true : false}
                            name="ToDate"
                            autoComplete="off"
                        />
                    </div>
                </Form.Group>
            </div>
            <div className="col-md-3" >
                <Form.Group className="mb-3">
                    <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                        <Select
                            onChange={(e) => FranchiseSelect(e)}
                            isClearable
                            options={FranchiseListOption}
                            className="godown-select-container"
                            classNamePrefix="godown-select"
                            placeholder="Select Franchise"
                            styles={customStyles}
                        />
                    </div>
                </Form.Group>
            </div>
            <div className="col-md-3">
                <button className="btn exportbtn" disabled={ToDate === "" || FromDate === ""} style={{ width: "auto" }} onClick={() => CommonExport("export_debit_credit_note_report", { "FromDate": FromDate, "ToDate": ToDate, "limit": userLimit, "franchise_id": Franchise?.value})}>
                    <i className="bi bi-filetype-xlsx"></i>
                    Export
                </button>
            </div>
        </div>
        <div className="row">
            <div className="col d-flex">
                <DataTableFilter
                    searchType={"Invoice ID."}
                    filterType={"debit-credit-note-report"}
                    userSearch={userSearch}
                    setUserSearch={setUserSearch}
                    userType={userType}
                    setUserType={setUserType}
                    userLimit={userLimit}
                    setUserLimit={setUserLimit}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    SelectUserTypeFnct={SelectUserTypeFnct}
                    buttonvisible={false}
                    searchdisable={false}
                />
            </div>
        </div>
        <div className="tableView">
            <Scrollbars
                style={{ height: parseInt(DebitCreditNoteReportList?.pagination?.total_records) === DebitCreditNoteReportList?.list?.length ? "calc(100vh - 206px)" : DebitCreditNoteReportList?.pagination?.total_records > 10 ? "calc(100vh - 240px)" : "calc(100vh - 206px)" }}
                renderView={props => <div {...props} className="view" />}
                renderTrackHorizontal={renderTrack}
                className="ScrollbarsSidebar"
            >
                <Table responsive bordered hover >
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNo</th>
                            <th>Franchise Code</th>
                            <th>Name</th>
                            <th>Credit Amount</th>
                            <th>Debit  Amount</th>
                            <th>Credit/Debit Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {DebitCreditNoteReportList?.list.map((item, index) => {
                            return (<React.Fragment key={index}>
                                <tr key={index}>
                                    <td style={{ width: '10px' }}>
                                        <button
                                            className={activeTableDetails === item?.id ? "btn actionbtn active" : "btn actionbtn"}
                                            onClick={() => activeTableDetails === item?.id ? ElseMoreDetails() : ViewMoreDetails(item?.id)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={activeTableDetails === item?.id ? true : false}
                                        >
                                            <Image src={MoreIcon} alt="more icon" />
                                        </button>
                                    </td>
                                    <td>
                                        {GetCurrentTableSRNo(index, DebitCreditNoteReportList?.pagination?.current_page, DebitCreditNoteReportList?.pagination?.record_limit)}
                                    </td>
                                    <td>{GetFranchiseData(item?.franchise_id)?.FranchiseCode}</td>
                                    <td>{GetFranchiseData(item?.franchise_id)?.FranchiseName}</td>
                                    <td>{AmountNumberFormat(item.credit_amt)}</td>
                                    <td>{AmountNumberFormat(item.debit_amt)}</td>
                                    <td>{CommonDateTime(item.deposit_date, "LL", "show")}</td>
                                </tr>
                                <Collapse in={activeTableDetails === item?.id ? true : false}>
                                    <tr className="tablecollapsewraps" id={"tablecollpase" + item?.id}>
                                        <td colSpan="8" >
                                            <Table bordered hover className="table">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Adjusted By</th>
                                                        <td>{GetUserData(item?.author)?.UserName}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Reasons</th>
                                                        <td>{item.reason === null ? "-" : item.reason}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Remarks</th>
                                                        <td>{item.remarks === null ? "-" : item.remarks}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                </Collapse>
                            </React.Fragment>)
                        })}
                        {DebitCreditNoteReportList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">Debit/Credit Notes Report list not found</td></tr>}
                        {DebitCreditNoteReportList?.list?.length !== 0 && (<React.Fragment>
                            <tr className="totaltd border">
                                <th colSpan={4} className="text-center">Page total</th>
                                <td>{AmountNumberFormat(TotalTableCount(DebitCreditNoteReportList?.list, "credit_amt"))}</td>
                                <td>{AmountNumberFormat(TotalTableCount(DebitCreditNoteReportList?.list, "debit_amt"))}</td>
                            </tr>
                            <tr className="totaltd border">
                                <th colSpan={4} className="text-center">Grand total</th>
                                <td>{AmountNumberFormat(DebitCreditNoteReportList?.grand_credit)}</td>
                                <td>{AmountNumberFormat(DebitCreditNoteReportList?.grand_debit)}</td>
                            </tr>
                        </React.Fragment>)}
                    </tbody>
                </Table>
            </Scrollbars>
            {DebitCreditNoteReportList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={currentPage}
                paginationLength={DebitCreditNoteReportList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        <OrderDetailsViewer
            OrderViewerModal={OrderViewerModal}
            setOrderViewerModal={setOrderViewerModal}
        />
        {show !== null && (
            <Modal show={show !== null} className="errorDetailsModal" onHide={() => setShow(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Error Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflow: "auto" }}>
                    <p dangerouslySetInnerHTML={{ __html: responseText(show?.invoice_response) }}>
                    </p>
                </Modal.Body>
            </Modal>
        )}
    </section>)
}

export default DebitCreditNoteReport;

