/* eslint-disable */
import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import { useSelector } from "react-redux";
import CommonLoader from "../../Common/CommonLoader";
import ErpApiError from "./ErpApiError";
import ErpInvoiceList from "./ErpInvoiceList";
import DeliveryNoteFrameNumber from "./DeliveryNoteFrameNumber";
import PurchaseOrderTab from "./PurchaseOrderTab";
import PurchaseReceiptTab from "./PurchaseReceiptTab";

const ERTApiFailures = (props) => {
    const [ OrderViewerModal, setOrderViewerModal ] = useState({
        open: false,
        Title: "",
        Data: {}
    });
    const [ Loader, setLoader ] = useState(false);
    const [ Tab, setTab ] = useState("erp_api_error");
    const { selfInfo } = useSelector((state) => state.adminReducers);

    // error message modal close
    const CloseErrorMessageModal = () => {
        setOrderViewerModal({
            ...OrderViewerModal,
            open: false,
            Title: "",
            Data: {}
        });
    };
    
    return(<section className="Main_section">
        <div className="MainHeader">
            {selfInfo?.user?.user_type ==="super_admin" ? ( <ul className="MainHeader_Tab">
                <li className={Tab === "erp_api_error" ? "active" : ""} onClick={() => setTab("erp_api_error")}>Sales Order Error</li>
                <li className={Tab === "erp_invoice_error" ? "active" : ""} onClick={() => setTab("erp_invoice_error")}>ERP Invoice Error</li>
                <li className={Tab === "delivery_note_frame_number" ? "active" : ""} onClick={() => setTab("delivery_note_frame_number")}>Delivery Note-Frame Number</li>
                <li className={Tab === "purchase_order" ? "active" : ""} onClick={() => setTab("purchase_order")}>Purchase Order</li>
                <li className={Tab === "purchase_receipt" ? "active" : ""} onClick={() => setTab("purchase_receipt")}>Purchase Receipt</li>
            </ul>) : ( <ul className="MainHeader_Tab">
                <li className={Tab === "erp_api_error" ? "active" : ""} onClick={() => setTab("erp_api_error")}>Sales Order Error</li>
                <li className={Tab === "erp_invoice_error" ? "active" : ""} onClick={() => setTab("erp_invoice_error")}>ERP Invoice Error</li>
                <li className={Tab === "delivery_note_frame_number" ? "active" : ""} onClick={() => setTab("delivery_note_frame_number")}>Delivery Note-Frame Number</li>
            </ul>)}
        </div>
        <div className="tabletabs_scrolbar">
            {Tab === "erp_api_error" ? (
                <ErpApiError setLoader={setLoader} OrderViewerModal={OrderViewerModal} setOrderViewerModal={setOrderViewerModal} />
            ) : Tab === "erp_invoice_error" ? (<ErpInvoiceList setLoader={setLoader} OrderViewerModal={OrderViewerModal} setOrderViewerModal={setOrderViewerModal} />) 
            : Tab === "delivery_note_frame_number" ? (<DeliveryNoteFrameNumber setLoader={setLoader} OrderViewerModal={OrderViewerModal} setOrderViewerModal={setOrderViewerModal} />) :
            Tab === "purchase_order" ? (<PurchaseOrderTab setLoader={setLoader} OrderViewerModal={OrderViewerModal} setOrderViewerModal={setOrderViewerModal} /> ) : 
            (<PurchaseReceiptTab setLoader={setLoader} OrderViewerModal={OrderViewerModal} setOrderViewerModal={setOrderViewerModal} />)}
        </div>
        {OrderViewerModal?.open && (
            <Modal show={OrderViewerModal?.open} className="errorDetailsModal" onHide={()=> CloseErrorMessageModal()}>
                <Modal.Header closeButton>
                <Modal.Title>{OrderViewerModal?.Title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflow: "auto"}}>
                    <p>
                        {OrderViewerModal?.Data?.response === undefined ? "No Error Found" : OrderViewerModal?.Data?.response}
                    </p>
                </Modal.Body>
            </Modal>
        )}

        {Loader && (<CommonLoader />)}
    </section>)
}

export default ERTApiFailures;