/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table } from 'react-bootstrap';
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link, useNavigate } from "react-router-dom";
import { WebSocketContext } from "../../App";
import { UPDATE_POINT_VALUE_SETTINGS, ADD_POINT_VALUE_SETTINGS } from "../Shared/constant";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../Common/CommonPagination";
import GetCurrentTableSRNo from "../Common/GetCurrentTableSRNo";

const PointSettings = (props) => {
    const { RouteName, deleteCommonStateModal, setdeleteCommonStateModal } = props;
    const {  device_id, selfInfo, PointSettingsList } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ReSells[4]?.Edit || PermissionsAccess?.ReSells[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "refurbished_point_value_list",
                "request" : { 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "order_by" :"updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // create points setting 
    const cmnFnctPointSettngs = () => {
        Navigate(ADD_POINT_VALUE_SETTINGS);
    };

    // delete setting points
    const DeletePoint = (data) => {
        setdeleteCommonStateModal({
            ...deleteCommonStateModal,
            title: "<i class='bi bi-trash3' style='color: red'></i> Delete Point",
            description: `Are you sure want to delete this <b>${data?.start_point}-${data?.end_point}</b> range ?`,
            open: !deleteCommonStateModal?.open,
            delete_id: data?.id,
            delete_url: "Delete-Point"
        });
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={""}
            searchType={"Point Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells[4]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Point Settings",
                function: cmnFnctPointSettngs
            }}
            searchdisable={false}
            ActiveInactiveFilter={false}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: PointSettingsList?.pagination?.total_records > 10 ? "calc(100vh - 233px)" : "calc(100vh - 198px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table responsive bordered hover >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Start Point</th>
                            <th>End Point</th>
                            <th>Max %</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells[4]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {PointSettingsList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, PointSettingsList?.pagination?.current_page, PointSettingsList?.pagination?.record_limit)}    
                                </td>
                                <td>{elm?.start_point}</td>
                                <td>{elm?.end_point}</td>
                                <td>{elm?.max_percentage}</td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells[4]?.Edit) && (<td>
                                    <Link className="btn edittable" to={`${UPDATE_POINT_VALUE_SETTINGS}/${elm?.id}`}>
                                        <i className="bi bi-pencil-square"></i>
                                    </Link>
                                    <button className="btn edittable" style={{ marginLeft: "5px" }} onClick={() => DeletePoint(elm)}>
                                        <i className="bi bi-trash3"></i>
                                    </button>
                                </td>)}
                            </tr>)
                        })}
                        {PointSettingsList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="7">Point settings list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {PointSettingsList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={PointSettingsList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
    </section>)
}

export default PointSettings;